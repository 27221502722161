import { Tooltip, IconButton, Radio } from '@mui/material';
import { Person as PersonIcon } from '@mui/icons-material';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import StyledWrapperTable from '../../pages/IndividualProgram/style';
import { AssignmentsButton } from '../ActionButtons/ActionButtons';
import AssignReviewButton from './AssignReviewButton';

const AssignReviewColumns = (
	noActions,
	chosenReviews,
	setOuterReviewer,
	tableName,
	stageID,
	handleReviewerSelect,
	isBulk,
	setReviewerName
) => {
	const greyIconTheme = createTheme({
		palette: {
			secondary: {
				main: '#0000008a'
			}
		}
	});

	let currentReviewer = '';
	const columns = [];

	if (tableName === 'Reassign Reviewer Pool') {
		columns?.push({
			field: 'bypass_group_id',
			headerName: ' ',
			width: 40,
			renderCell: (cellValues) => (
				<Radio
					checked={currentReviewer === cellValues?.id}
					value={cellValues.id}
					onChange={() => {
						currentReviewer = cellValues?.id;
						setOuterReviewer(cellValues?.id);
						setReviewerName?.(`${cellValues?.row?.first_name} ${cellValues?.row?.last_name}`);
					}}
					name="radio-buttons"
				/>
			)
		});
	}

	columns?.push(
		...[
			{
				field: 'name',
				headerName: 'Name',
				renderCell: (cellValues) => (
					<Tooltip
						arrow
						placement="top"
						width={'200px'}
						title={
							<StyledWrapperTable.CenteredText>
								{`${cellValues?.row?.first_name} ${cellValues?.row?.last_name}`}
							</StyledWrapperTable.CenteredText>
						}
					>
						{tableName === 'Assigned' ? (
							<StyledWrapperTable.CellContainer margin={!cellValues?.formattedValue}>
								<PersonIcon color={'primary'} />

								<StyledWrapperTable.CardRowInfo
									width={'100%'}
									data-testid={`${cellValues?.field}-${cellValues?.id}`}
								>
									{`${cellValues?.row?.first_name} ${cellValues?.row?.last_name}`}
								</StyledWrapperTable.CardRowInfo>
							</StyledWrapperTable.CellContainer>
						) : (
							<StyledWrapperTable.CellContainer margin={!cellValues?.formattedValue}>
								<ThemeProvider theme={greyIconTheme}>
									<PersonIcon color={'secondary'} />
								</ThemeProvider>

								<StyledWrapperTable.CardRowInfo
									width={'100%'}
									data-testid={`${cellValues?.field}-${cellValues?.id}`}
								>
									{`${cellValues?.row?.first_name} ${cellValues?.row?.last_name}`}
								</StyledWrapperTable.CardRowInfo>
							</StyledWrapperTable.CellContainer>
						)}
					</Tooltip>
				),
				flex: 1,
				minWidth: 200
			},
			{
				field: 'total_reviews',
				headerName: 'Presently Assigned',
				flex: 1,
				renderCell: (cellValues) => <>{cellValues?.formattedValue || '0'}</>
			},
			{
				field: 'not_started',
				headerName: 'Not Started',
				flex: 1,
				renderCell: (cellValues) => <>{cellValues?.formattedValue || '0'}</>
			},
			{
				field: 'in_progress',
				headerName: 'In Progress',
				flex: 1,
				renderCell: (cellValues) => <>{cellValues?.formattedValue || '0'}</>
			},
			{
				field: 'submitted',
				flex: 1,
				renderHeader: () => (
					<div style={{ display: 'flex', gap: '5px', alignItems: 'baseline' }}>
						<span style={{ fontWeight: '500', fontSize: '14px' }}>Submitted (since inception)</span>
					</div>
				),
				renderCell: (cellValues) => <>{cellValues?.formattedValue || '0'}</>
			}
		]
	);

	!noActions &&
		columns.push({
			field: 'action',
			headerName: 'Actions',
			description: 'Actions',
			renderCell: (cellValues) => {
				return (
					<div style={{ display: 'flex', gap: '5px', alignItems: 'center' }}>
						{tableName === 'Reviewer Pool' ? (
							<AssignReviewButton
								isBulk={isBulk}
								stageID={stageID}
								reviewerID={cellValues?.row?.reviewer_id}
								onClick={() => handleReviewerSelect(cellValues?.row)}
							/>
						) : chosenReviews ? (
							<Tooltip title="Remove from assigning list" placement="top">
								<IconButton
									isBulk={isBulk}
									onClick={() => handleReviewerSelect(cellValues?.row)}
									aria-label="Remove from assigning list"
									color="secondary"
								>
									<img src={'/images/remove.svg'}></img>
								</IconButton>
							</Tooltip>
						) : (
							<AssignmentsButton stageID={stageID} item={cellValues?.row} />
						)}
					</div>
				);
			},
			minWidth: 450
		});

	return columns;
};

export default AssignReviewColumns;

import { useState, useEffect, useRef } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';

import ReviewToApplicationColumns from './revToAppColumns';
import AssignedReviewsTable from './AssignedReviewsTable';
import EvalToRevTable from './EvalToRevTable';

import { createTheme, ThemeProvider } from '@mui/material/styles';
import {
	MenuItem,
	FormControl,
	Select,
	Button,
	Alert,
	Stack,
	Collapse,
	IconButton
} from '@mui/material';

import { Close as CloseIcon } from '@mui/icons-material';

import { Table } from '../../../components/Table';
import StyledWrapper from '../style';
import { BulkEvalAssignmentsModal } from '../../../components/AssignReviewModal/BulkEvalAssignmentsModal';
import ReviewFormIcon from '../../../assets/customIcons/reviewForm';
import LinkReviewIcon from '../../../assets/customIcons/linkReviewIcon';
import BulkRevToEvalModal from '../../../components/AssignReviewModal/BulkRevToEvalModal';
import { AssignmentsButton } from '../../../components/ActionButtons/ActionButtons';
import { useGetAvailableReviewersQuery } from '../../../services/endpoints/reviewEndpoints';
import { useGetRevToAppEmailsQuery } from '../../../services/endpoints/reviewEndpoints';
import usePaginatedCall from '../../../hooks/usePaginatedCall';

import { usePermissions } from '../../../hooks/usePermissions';

const ManageReviews = () => {
	let { programID } = useParams();
	programID = programID?.replace('p-', '');

	const location = useLocation();
	const currentPath = location.pathname?.split('/');

	const navigate = useNavigate();
	const [alertBalloon, setAlertBalloon] = useState(false);
	const [columnWidth, setColumnWidth] = useState({});
	const [chosenRows, setChosenRows] = useState([]);
	const [chosenStages, setChosenStages] = useState([]);
	const { hasEditPermissions } = usePermissions();

	const stateRef = useRef();
	stateRef.current = chosenRows;

	const revToAppColumns = ReviewToApplicationColumns();

	const [currentView, setCurrentView] = useState(0);
	const [showBulkAssign, setShowBulkAssign] = useState(false);
	const [showBulkAssignRevs, setShowBulkAssignRevs] = useState(false);
	const [currentColumns, setCurrentColumns] = useState(revToAppColumns);

	const [currentKey, setCurrentKey] = useState('revToApp-table');

	const reviewerPoolPagination = usePaginatedCall(useGetAvailableReviewersQuery, {
		filterBaseName: 'PROGRAM_REVIEWS'
	});

	const [finalPagination, setFinalPagination] = useState(reviewerPoolPagination);
	const [noReviewers, setNoReviewers] = useState(false);

	const theme = createTheme({
		palette: {
			secondary: {
				main: '#9C27B0'
			}
		}
	});

	const handleViewChange = () => {
		const currentPage = currentPath[currentPath?.length - 1];

		if (currentPage === 'manage-assigned') {
			setCurrentView(2);
			setFinalPagination({});
			setCurrentKey('manageRevs-table');
		} else if (currentPage === 'assign-by-application') {
			setCurrentView(1);
			setFinalPagination({});
			setCurrentKey('evalToRev-table');
		} else {
			setCurrentView(0);
			setCurrentColumns(revToAppColumns);
			setFinalPagination(reviewerPoolPagination);
			setCurrentKey('revToApp-table');
		}
	};

	const handleAlert = (message, status, dismissing = true) => {
		setAlertBalloon({ isOpen: true, message, status });

		if (dismissing) {
			setTimeout(() => {
				setAlertBalloon(false);
			}, 3000);
		}
	};

	useEffect(() => {
		handleViewChange();
	}, [location, reviewerPoolPagination?.rows]);

	useEffect(() => {
		setNoReviewers(finalPagination?.rows && finalPagination?.rowCount <= 0);
	}, [finalPagination]);

	return noReviewers ? (
		<Stack sx={{ width: '100%', marginTop: '20px' }} spacing={2}>
			<Alert variant="outlined" severity="info">
				Reviewers have not been added to this program.
				<br />
				Once reviewers have been added to this program, they will appear on this page and can be
				assigned to applications.
				<br />
				Users may take up to five minutes to appear in the list after they are added in Grants
				Network.
			</Alert>
		</Stack>
	) : (
		<>
			<Stack sx={{ width: '100%', marginTop: '20px' }} spacing={2}>
				<Alert variant="outlined" severity="info">
					Users may take up to five minutes to appear in the list after they are added in Grants
					Network.
				</Alert>
			</Stack>
			<ThemeProvider theme={theme}>
				<StyledWrapper.Toolbar
					style={{ marginTop: '30px', marginBottom: '20px', marginRight: '20px' }}
				>
					<StyledWrapper.ActionsContainerOutermost>
						<FormControl fullWidth>
							<Select
								id="current-view-select"
								value={currentPath[currentPath?.length - 1]}
								onChange={(e) => {
									let newPath = currentPath;
									newPath.pop();
									newPath.push(e.target.value);
									setChosenRows([]);
									navigate(newPath?.join('/'));
								}}
								sx={{ width: 'fit-content' }}
								size="small"
							>
								<MenuItem value={'assign-by-reviewer'}>Assign Reviewers to Applications</MenuItem>
								<MenuItem value={'assign-by-application'}>
									Assign Applications to Reviewers
								</MenuItem>
								<MenuItem value={'manage-assigned'}>Manage Assigned Reviews</MenuItem>
							</Select>
						</FormControl>
						{currentView === 0 && hasEditPermissions ? (
							<Button
								disabled={chosenRows?.length === 0}
								variant="outlined"
								sx={{ width: '250px', display: 'flex', gap: '6px', textWrap: 'nowrap' }}
								onClick={() => setShowBulkAssignRevs(true)}
							>
								Bulk Assign
								<LinkReviewIcon isActive={chosenRows?.length !== 0} />
								<ReviewFormIcon
									isBlueprint
									isActive={chosenRows?.length !== 0}
									forceOldBlueColor={chosenRows?.length !== 0}
								/>
							</Button>
						) : currentView === 1 && hasEditPermissions ? (
							<Button
								disabled={chosenRows?.length === 0}
								variant="outlined"
								sx={{ width: '220px', display: 'flex', gap: '6px' }}
								onClick={() => setShowBulkAssign(true)}
							>
								Bulk Assign
								<ReviewFormIcon
									customDisabledColor={'rgba(0, 0, 0, 0.26)'}
									isBlueprint
									isActive={chosenRows?.length !== 0}
									forceOldBlueColor={chosenRows?.length !== 0}
								/>
								<LinkReviewIcon isActive={chosenRows?.length !== 0} />
							</Button>
						) : (
							hasEditPermissions && (
								<AssignmentsButton
									item={chosenRows}
									isBulk
									disabled={chosenRows?.length === 0}
									handleAlert={handleAlert}
									alertBalloon={alertBalloon}
									stages={chosenStages}
									resetSelection={() => setChosenRows([])}
								/>
							)
						)}
					</StyledWrapper.ActionsContainerOutermost>
				</StyledWrapper.Toolbar>

				<Collapse in={alertBalloon}>
					<Alert
						variant="outlined"
						severity={alertBalloon?.status}
						action={
							<IconButton
								aria-label="close"
								color={alertBalloon?.status}
								size="small"
								onClick={() => {
									setAlertBalloon(false);
								}}
							>
								<CloseIcon fontSize="inherit" />
							</IconButton>
						}
						sx={{ mb: 2, mt: 2 }}
					>
						{alertBalloon?.message}
					</Alert>
				</Collapse>

				<StyledWrapper.TableContainer>
					{currentKey === 'manageRevs-table' ? (
						<AssignedReviewsTable
							handleAlert={handleAlert}
							alertBalloon={alertBalloon}
							setChosenRows={setChosenRows}
							setChosenStages={setChosenStages}
							emailType={'Reviewer'}
							emailEndpoint={useGetRevToAppEmailsQuery}
						/>
					) : currentKey === 'evalToRev-table' ? (
						<EvalToRevTable
							hasEditPermissions={hasEditPermissions}
							handleAlert={handleAlert}
							alertBalloon={alertBalloon}
							setChosenRows={setChosenRows}
						/>
					) : (
						<Table
							checkboxSelection={hasEditPermissions}
							isRowSelectable={(params) => params?.row?.reviewer_id}
							onSelectChange={(e) => setChosenRows(e)}
							disableSelectionOnClick
							hideFilters
							key={currentKey}
							getRowId={(cell) => cell?.reviewer_id}
							customRowID={'reviewer_id'}
							columns={currentColumns}
							rowHeight={true}
							enableAutoPageSize
							groupingColDef={() => groupingColDef(columnWidth)}
							onColumnResize={(e) => {
								const newColumnSizes = e?.colDef?.field;
								const width = e?.width;
								setColumnWidth({ ...columnWidth, [newColumnSizes]: width });
							}}
							rows={finalPagination?.rows?.results || []}
							rowCount={finalPagination?.rowCount}
							pagination={finalPagination?.pagination}
							pageSize={finalPagination?.pageSize}
							paginationMode={finalPagination?.paginationMode}
							onPageChange={(e) => finalPagination?.onPageChange(e)}
							onPageSizeChange={(e) => finalPagination?.onPageSizeChange(e)}
							page={finalPagination?.page}
							loading={finalPagination?.loading}
							sortingMode={finalPagination?.sortingMode}
							onSortModelChange={(e) => finalPagination?.onSortModelChange(e)}
							filterModel={finalPagination?.filterModel}
							sortModel={finalPagination?.sortModel}
							emailType={'Reviewer'}
							emailEndpoint={useGetRevToAppEmailsQuery}
						/>
					)}
				</StyledWrapper.TableContainer>
			</ThemeProvider>

			{showBulkAssign && hasEditPermissions && (
				<BulkEvalAssignmentsModal
					chosenStages={chosenRows}
					closeModal={() => setShowBulkAssign(false)}
					openModal={showBulkAssign}
					handleAlert={handleAlert}
				/>
			)}

			{showBulkAssignRevs && hasEditPermissions && (
				<BulkRevToEvalModal
					chosenReviews={chosenRows}
					closeModal={() => setShowBulkAssignRevs(false)}
					openModal={showBulkAssignRevs}
					handleAlert={handleAlert}
				/>
			)}
		</>
	);
};

export default ManageReviews;

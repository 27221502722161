import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { checkOverflow, getChipColor, getChipTextColor } from '../../../utils/utilFunctions';

import TableTitle from '../../../components/TableTitle/TableTitle';
import BasicCellText from './BasicCellText';
import TitleWithIdCell from './TitleWithIdCell';

import { GRID_CHECKBOX_SELECTION_COL_DEF } from '@mui/x-data-grid';

import { Tooltip, Chip } from '@mui/material';

const ManageReviewerCol = () => {
	const navigate = useNavigate();
	const [openTooltip, setOpenTooltip] = useState(0);

	return [
		GRID_CHECKBOX_SELECTION_COL_DEF,
		{
			field: 'submitter',
			headerName: 'Submitter',
			description: 'Submitter',
			renderCell: (cellValues) => (
				<BasicCellText
					openTooltip={openTooltip}
					setOpenTooltip={setOpenTooltip}
					cellValues={cellValues}
				/>
			),
			flex: 1,
			resizable: true
		},
		{
			field: 'projectTitle',
			headerName: 'Application (Project Title)',
			description: 'Application (Project Title)',
			renderCell: (cellValues) => {
				const titleData = {
					displayId: cellValues?.formattedValue?.displayId,
					title: cellValues?.formattedValue?.title,
					status: cellValues?.row?.status,
					entityType: 'Application Process'
				};

				return <>{cellValues?.formattedValue && <TitleWithIdCell titleData={titleData} />}</>;
			},
			flex: 3,
			resizable: true
		},
		{
			field: 'stageName',
			headerName: 'Evaluation Stage Name',
			description: 'Evaluation Stage Name',
			renderCell: (cellValues) => {
				const titleData = {
					displayId: cellValues?.formattedValue?.displayId,
					title: cellValues?.formattedValue?.title,
					status: cellValues?.row?.status,
					entityType: 'Evaluation Stage'
				};

				return (
					<>
						{cellValues?.formattedValue && (
							<TitleWithIdCell
								titleData={titleData}
								customColor={getChipColor(cellValues?.row?.status)}
								isActive={true}
								isCurrentStage={cellValues?.row?.isCurrentStage}
							/>
						)}
					</>
				);
			},
			flex: 3
		},
		{
			field: 'status',
			headerName: 'Stage Status',
			description: 'Stage Status',
			renderCell: (cellValues) => (
				<>
					{cellValues?.formattedValue && (
						<Tooltip
							open={openTooltip === `status-${cellValues?.id}`}
							onOpen={(e) => checkOverflow(e) && setOpenTooltip(`status-${cellValues?.id}`)}
							onClose={() => setOpenTooltip(0)}
							arrow
							placement="top"
							title={cellValues?.formattedValue}
						>
							<Chip
								data-testid={`${cellValues?.field}-${cellValues?.id}`}
								label={cellValues?.formattedValue}
								sx={{
									backgroundColor: getChipColor(cellValues?.formattedValue),
									color: getChipTextColor(cellValues?.formattedValue)
								}}
								size="small"
							/>
						</Tooltip>
					)}
				</>
			),
			flex: 1.5
		},
		{
			field: 'totalReviewers',
			headerName: 'Number of Reviewers',
			description: 'Number of Reviewers',
			renderCell: (cellValues) => (
				<>
					{cellValues?.formattedValue && (
						<BasicCellText
							openTooltip={openTooltip}
							setOpenTooltip={setOpenTooltip}
							cellValues={cellValues}
						/>
					)}
				</>
			),
			resizable: true,
			flex: 1
		},
		{
			field: 'reviewForms',
			headerName: 'Review Forms',
			description: 'Review Forms',
			renderCell: (cellValues) => {
				const getTitleData = (review) => ({
					displayId: review.displayId,
					title: review.title,
					status: cellValues?.row?.status,
					entityType: 'Review Form'
				});

				const handleCardClick = (displayId) => {
					navigate(
						`/programs/${cellValues?.row?.programId}` +
							`/applications/${cellValues?.row?.projectTitle?.displayId}` +
							`/${cellValues?.row?.stageName?.displayId}` +
							`/${cellValues?.row?.masterFormId}-group` +
							`/${displayId}/content?formtype=application`
					);
				};

				return (
					<span style={{ display: 'flex', flexDirection: 'column' }}>
						{cellValues?.formattedValue &&
							cellValues?.formattedValue.map((review, index) => (
								<TableTitle
									key={index}
									titleData={getTitleData(review)}
									handleCardClick={() => handleCardClick(review.displayId)}
								/>
							))}
					</span>
				);
			},
			flex: 3,
			resizable: true
		}
	];
};

export default ManageReviewerCol;

import React, { useState, useEffect, useRef, useCallback } from 'react';

import StyledWrapper from './style';
import { useFileUtils } from '../utils';

import FormHelperText from '@mui/material/FormHelperText';
import IconButton from '@mui/material/IconButton';
import LoadingButton from '@mui/lab/LoadingButton';
import FileUploadIcon from '@mui/icons-material/FileUpload';

import Tooltip from '@mui/material/Tooltip';
import DeleteIcon from '@mui/icons-material/Delete';
import CircularProgress from '@mui/material/CircularProgress';
import { MAX_FILENAME_LENGTH } from '../../../constants/fileAttachmentConfig';
export const FileAttachment_Upload = React.forwardRef((props, _ref) => {
	let { disabled, onSave, onDelete, onBlur, name, element } = props;
	const { sendFileToServer, downloadFile } = useFileUtils();
	const inputFile = useRef(null);
	const [loading, setLoading] = React.useState(false);
	const [selectedFiles, setSelectedFiles] = useState([]);
	const [allFiles, setAllFiles] = useState(props.element?.src || []);
	const [fileError, setFileError] = useState('');

	const handleInvalidFiles = (newFiles) => {
		const invalidFiles = newFiles.filter((file) => file.name.length > MAX_FILENAME_LENGTH);
		if (invalidFiles.length > 0) {
			setFileError('Some file names exceed the allowed limit (200 characters)');
			return true;
		}
		setFileError('');
		return false;
	};
	const handleFileChange = (event) => {
		const newFiles = Array.from(event.target.files);

		// Validate each new file
		if (handleInvalidFiles(newFiles)) {
			return;
		}
		// File names are within the allowed limit
		setSelectedFiles((prevSelectedFiles) => [...prevSelectedFiles, ...newFiles]);
	};

	useEffect(() => {
		// Trigger the uploadFiles function after updating selectedFiles
		if (selectedFiles.length > 0) {
			uploadFiles();
		}
	}, [selectedFiles]);

	const handleFileDownload = (filePath) => {
		downloadFile(filePath);
	};

	const handleDelete = useCallback(
		(index) => {
			const updatedFiles = [...allFiles];
			updatedFiles.splice(index, 1);
			setAllFiles(updatedFiles);
		},
		[allFiles]
	);

	function readFile(file) {
		return new Promise((resolve, reject) => {
			const reader = new FileReader();

			reader.onload = (loadEvent) => {
				resolve(loadEvent.target.result);
			};

			reader.onerror = (error) => {
				reject(error);
			};

			reader.readAsArrayBuffer(file);
		});
	}

	const uploadFiles = async () => {
		if (selectedFiles.length === 0) {
			setFileError('No files selected for upload.');
			return;
		}

		setLoading(true);

		try {
			const hasDuplicate = selectedFiles.some((newFile) => {
				const fileName = newFile.name;
				return allFiles.some((prevFile) => prevFile.filename === fileName);
			});

			if (hasDuplicate) {
				setFileError('Duplicates found in selected files.');
				setSelectedFiles([]);
				setLoading(false);
				return;
			}

			const uploadedFiles = [];

			for (const file of selectedFiles) {
				try {
					const fileData = await readFile(file);

					const data = await sendFileToServer(file.name, fileData);
					uploadedFiles.push(data);
				} catch (error) {
					console.error('File is empty!', error);
				}
			}
			setAllFiles((prevAllFiles) => [...prevAllFiles, ...uploadedFiles]);
			setSelectedFiles([]);
			setLoading(false);
			onBlur();
		} catch (error) {
			setFileError(error);
			setLoading(false);
		}
	};

	useEffect(() => {
		if (allFiles) {
			const triggerBlur = document.getElementById('hiddenBlurTriggerInput');
			triggerBlur?.click();
		}
	}, [allFiles]);

	useEffect(() => {
		onSave(allFiles);
	}, [allFiles]);

	return (
		<>
			<StyledWrapper.CustomTextFieldWrapper>
				<StyledWrapper.CustomLabel htmlFor="custom-text-field">
					Attachments
				</StyledWrapper.CustomLabel>
				<div className="inner-form-group" name={name}>
					<StyledWrapper.BorderOutline
						className={'customInputOutline'}
						disabled={disabled ? true : false}
					>
						{allFiles && (
							<>
								<div>
									{!loading && (
										<div style={{ display: 'flex', flexDirection: 'column', gap: '0px' }}>
											{allFiles.map((file, index) => {
												if (!file) {
													return null;
												}
												return (
													<div key={index} style={{ display: 'flex', alignItems: 'center' }}>
														<Tooltip title="Remove File" placement="top">
															<IconButton
																onClick={() => handleDelete(index)}
																aria-label={`delete-file-${index}`}
																color="error"
															>
																<DeleteIcon color="error" />
															</IconButton>
														</Tooltip>{' '}
														<span
															style={{ color: '#1565C0', cursor: 'pointer' }}
															onClick={() => handleFileDownload(`${file.uuid}-${file.filename}`)}
														>
															{file.filename}
														</span>
													</div>
												);
											})}
										</div>
									)}
									<LoadingButton
										disabled={disabled}
										startIcon={<FileUploadIcon />}
										variant="contained"
										component="label"
										sx={{
											fontWeight: '500!important',
											lineHeight: '1.75!important',
											fontSize: '13px!important',
											letterSpacing: '0.02857rem!important'
										}}
									>
										ADD FILE
										<input
											ref={inputFile}
											type="file"
											multiple
											hidden
											onChange={handleFileChange}
										/>
									</LoadingButton>

									{loading && (
										<IconButton>
											<CircularProgress />
										</IconButton>
									)}
								</div>
							</>
						)}
					</StyledWrapper.BorderOutline>
					{fileError && (
						<FormHelperText error={props.data?.error || fileError}>
							{fileError || 'Choose file and then click Upload Files button to finalize upload.'}
						</FormHelperText>
					)}
				</div>
				<StyledWrapper.CustomHelperText>
					Add at least one attachment.
				</StyledWrapper.CustomHelperText>
			</StyledWrapper.CustomTextFieldWrapper>
		</>
	);
});

FileAttachment_Upload.displayName = 'FileAttachment_Upload';

import { useState, useEffect } from 'react';

import { Table } from '../../../components/Table';
import PersonIcon from '../../../assets/customIcons/personIcon';
import BasicCellText from './BasicCellText';

import ManageReviewerCol from './manageReviewerCol';
import { useGetBulkReviewerTable } from '../../../hooks/useGetBulkReviewerTable';
import { useGetManageAssignedRevEmailsQuery } from '../../../services/endpoints/reviewEndpoints';

import { Stack, Alert } from '@mui/material';

const AssignedReviewsTable = ({ alertBalloon, setChosenRows, setChosenStages }) => {
	const manageReviewColumns = ManageReviewerCol();
	const [reload, setReload] = useState(false);
	const getTreeDataPath = (row) => row?.hierarchy;

	const { data, pagination } = useGetBulkReviewerTable();
	const hasApplications =
		pagination.isUninitialized ||
		pagination.loading ||
		pagination.filterModel.length > 0 ||
		data.length > 0;

	useEffect(() => {
		if (alertBalloon?.status === 'success') {
			setReload(true);
			setTimeout(function () {
				setReload(false);
			}, 10);
		}
	}, [alertBalloon]);

	const createHierarchyObject = (arr) => {
		const hierarchyObject = {};
		const stages = [];

		arr.forEach((element) => {
			const [hierarchyKey] = element.hierarchy;
			const { stageId } = element;

			if (!hierarchyObject[hierarchyKey]) {
				hierarchyObject[hierarchyKey] = [stageId];
			} else if (!hierarchyObject[hierarchyKey].includes(stageId)) {
				hierarchyObject[hierarchyKey].push(stageId);
			}

			if (element?.stageName?.title && !stages.includes(element?.stageName?.title))
				stages.push(element?.stageName?.title);
		});

		setChosenRows(hierarchyObject);
		setChosenStages(stages);
	};

	const checkIsRowSelectable = (params) =>
		params?.row?.stageId &&
		(params?.row?.appStatus.includes('In Progress') || params?.row?.appStatus === 'Not Started');

	const manageReviewGroupDef = () => ({
		hideDescendantCount: true,
		field: 'reviewer',
		headerName: 'Reviewer',
		description: 'Reviewer',
		valueFormatter: (cellValues) => {
			const row = cellValues?.api?.getRow(cellValues?.id);
			const currentCell = {
				formattedValue: row?.reviewer,
				field: 'type',
				id: cellValues?.id
			};
			return <BasicCellText cellValues={currentCell} StartIcon={PersonIcon} isLink isActive />;
		},
		flex: 1.5,
		resizable: true
	});

	const customGetRowHeight = (e) => (e.model.reviewForms?.length > 1 ? 'auto' : 52);

	return (
		<>
			{!reload && !hasApplications && (
				<Stack sx={{ width: '100%', marginTop: '20px' }} spacing={2}>
					<Alert variant="outlined" severity="info">
						There is nothing to manage.
						<br />
						There are no applications with a review stage status that allows management.
					</Alert>
				</Stack>
			)}
			{!reload && hasApplications && (
				<Table
					columns={manageReviewColumns}
					customGetRowHeight={customGetRowHeight}
					rows={data || []}
					rowCount={pagination?.rowCount}
					pagination={pagination?.pagination}
					pageSize={pagination?.pageSize}
					paginationMode={pagination?.paginationMode}
					onPageChange={(e) => pagination?.onPageChange(e)}
					onPageSizeChange={(e) => pagination?.onPageSizeChange(e)}
					page={pagination?.page}
					loading={pagination?.loading}
					checkboxSelection={true}
					hideDisabledCheckboxes={true}
					moveCheckboxColumn={true}
					isRowSelectable={checkIsRowSelectable}
					onSelectChange={(e) => createHierarchyObject(e)}
					disableSelectionOnClick
					key={'manageRevs-table'}
					treeData={true}
					getTreeDataPath={getTreeDataPath}
					groupingColDef={manageReviewGroupDef}
					emailType={'Reviewer'}
					emailEndpoint={useGetManageAssignedRevEmailsQuery}
					hideFilters
					rowsPerPageOptions={[5, 10, 20, 50]}
				/>
			)}
		</>
	);
};

export default AssignedReviewsTable;

import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import Tooltip from '@mui/material/Tooltip';
import Chip from '@mui/material/Chip';
import CheckIcon from '@mui/icons-material/Check';
import WarningIcon from '@mui/icons-material/Warning';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import StyledWrapper from './style';

import { getChipColorPrograms } from '../../utils/utilFunctions';
import { getLocalAuth } from '../../utils/environmentUtils';
import { checkOverflow, createAndDownloadFile } from '../../utils/utilFunctions';
import { Table } from '../../components/Table';
import PrintActions from '../../components/PrintActions/PrintActions';
import {
	useGetAllProgramsQuery,
	useLazyGetAllProgramsQuery
} from '../../services/endpoints/programsEndpoints';
import { formatTimestamp } from '../../utils/dateUtils';
import { numberToMoney } from '../../utils/numberUtils';
import usePaginatedCall from '../../hooks/usePaginatedCall';
import TableTitle from '../../components/TableTitle/TableTitle';
import { saveVariable, clearDefaultFilter } from '../../components/BaseLayout/slice';

const Programs = () => {
	const pagination = usePaginatedCall(useGetAllProgramsQuery, { filterBaseName: 'PROGRAMS' });

	const [openTooltip, setOpenTooltip] = useState(0);
	const [noPrograms, setNoPrograms] = useState(false);

	const navigate = useNavigate();
	const dispatch = useDispatch();
	const accountSource = useSelector((state) => state?.login?.accountSource);
	const apiToken = getLocalAuth(accountSource)?.access_token;

	const [getAllPrograms, { isLoading: csvDataIsLoading, isFetching: csvDataIsFetching }] =
		useLazyGetAllProgramsQuery();
	const tableExportAvailable = pagination?.rowCount > 0;

	const columns = [
		{
			field: 'title',
			headerName: 'Program',
			description: 'Program',
			renderCell: (cellValues) => {
				const displayId = cellValues.id;

				const { status, app_process_is_ready_to_publish } = cellValues.row ?? {};
				const title = cellValues.formattedValue;
				const titleData = {
					displayId,
					title,
					status,
					entityType: 'Program'
				};
				const handleCardClick = () => {
					dispatch(
						saveVariable([
							'program',
							{
								id: displayId,
								title: title
							}
						])
					);
					dispatch(clearDefaultFilter());

					if (app_process_is_ready_to_publish) {
						navigate(`/programs/p-${displayId}/overview`);
					} else {
						navigate(`/programs/p-${displayId}/application-setup/application`);
					}
				};
				return <TableTitle titleData={titleData} handleCardClick={handleCardClick} />;
			},
			flex: 1,
			minWidth: 400
		},
		{
			field: 'status',
			headerName: 'Status',
			description: 'Status',
			renderCell: (cellValues) => {
				return (
					<>
						<Tooltip
							open={openTooltip === `status-${cellValues?.id}`}
							onOpen={(e) => checkOverflow(e) && setOpenTooltip(`status-${cellValues?.id}`)}
							onClose={() => setOpenTooltip(0)}
							arrow
							placement="top"
							title={cellValues?.formattedValue}
						>
							<Chip
								data-testid={`${cellValues?.field}-${cellValues?.id}`}
								label={cellValues?.formattedValue}
								sx={{
									border: '1px solid black',
									backgroundColor: getChipColorPrograms(cellValues?.formattedValue),
									color: 'white'
								}}
								size="small"
							/>
						</Tooltip>
					</>
				);
			},
			flex: 1,
			align: 'right',
			minWidth: 100
		},
		{
			field: 'start_date',
			headerName: 'Start',
			description: 'Start',
			type: 'date',
			renderCell: (cellValues) => {
				return (
					<Tooltip
						open={openTooltip === `startDate-${cellValues?.id}`}
						onOpen={(e) => checkOverflow(e) && setOpenTooltip(`startDate-${cellValues?.id}`)}
						onClose={() => setOpenTooltip(0)}
						arrow
						placement="top"
						title={cellValues?.formattedValue}
					>
						<StyledWrapper.CardRowInfo
							data-testid={`${cellValues?.field}-${cellValues?.id}`}
							width={'80%'}
						>
							{formatTimestamp(cellValues?.formattedValue)}
						</StyledWrapper.CardRowInfo>
					</Tooltip>
				);
			},
			flex: 1,
			minWidth: 100
		},
		{
			field: 'end_date',
			headerName: 'End',
			description: 'End',
			type: 'date',
			renderCell: (cellValues) => {
				return (
					<Tooltip
						open={openTooltip === `endDate-${cellValues?.id}`}
						onOpen={(e) => checkOverflow(e) && setOpenTooltip(`endDate-${cellValues?.id}`)}
						onClose={() => setOpenTooltip(0)}
						arrow
						placement="top"
						title={cellValues?.formattedValue}
					>
						<StyledWrapper.CardRowInfo
							data-testid={`${cellValues?.field}-${cellValues?.id}`}
							width={'80%'}
						>
							{formatTimestamp(cellValues?.formattedValue)}
						</StyledWrapper.CardRowInfo>
					</Tooltip>
				);
			},
			flex: 1,
			minWidth: 100
		},
		{
			field: 'app_process_is_ready_to_publish',
			headerName: 'App Setup',
			description: 'App Setup',
			renderCell: (cellValues) => {
				return (
					<>
						<Tooltip
							arrow
							placement="top"
							title={
								cellValues?.row?.app_process_is_ready_to_publish ? 'Setup Finished' : 'Needs Setup'
							}
						>
							<StyledWrapper.CardRowInfo
								data-testid={`${cellValues?.field}-${cellValues?.id}`}
								width={'fit-content'}
							>
								{cellValues?.row?.app_process_is_ready_to_publish ? (
									<CheckIcon color="success" />
								) : (
									<WarningIcon color="warning" />
								)}
							</StyledWrapper.CardRowInfo>
						</Tooltip>
					</>
				);
			},
			flex: 1,
			align: 'center',
			minWidth: 100,
			maxWidth: 100
		},
		{
			field: 'department',
			headerName: 'Department',
			description: 'Department',
			renderCell: (cellValues) => {
				return (
					<>
						<Tooltip
							open={openTooltip === `department-${cellValues?.id}`}
							onOpen={(e) => checkOverflow(e) && setOpenTooltip(`department-${cellValues?.id}`)}
							onClose={() => setOpenTooltip(0)}
							arrow
							placement="top"
							title={cellValues?.formattedValue}
						>
							<StyledWrapper.CardRowInfo
								data-testid={`${cellValues?.field}-${cellValues?.id}`}
								width={'80%'}
							>
								{cellValues?.formattedValue}
							</StyledWrapper.CardRowInfo>
						</Tooltip>
					</>
				);
			},
			flex: 1,
			minWidth: 100
		},
		{
			field: 'available_funds',
			headerName: 'Total Funding',
			description: 'Total Funding',
			type: 'number',
			renderCell: (cellValues) => {
				const value = cellValues.formattedValue.replaceAll(',', '');
				return (
					<>
						<Tooltip
							open={openTooltip === `total-${cellValues?.id}`}
							onOpen={(e) => checkOverflow(e) && setOpenTooltip(`total-${cellValues?.id}`)}
							onClose={() => setOpenTooltip(0)}
							arrow
							placement="top"
							title={value}
						>
							<StyledWrapper.CardRowInfo
								data-testid={`${cellValues?.field}-${cellValues?.id}`}
								width={'80%'}
							>
								{cellValues?.formattedValue ? `${numberToMoney(parseInt(value))}` : '--'}
							</StyledWrapper.CardRowInfo>
						</Tooltip>
					</>
				);
			},
			flex: 1,
			minWidth: 100,
			headerAlign: 'left'
		},
		/* -------------------------------------- */
		/* This code is temporarily commented out */
		/* -------------------------------------- */
		// {
		// 	field: 'budget_template',
		// 	headerName: 'Budget Template',
		// 	description: 'Budget Template',
		// 	renderCell: (cellValues) => {
		// 		return (
		// 			<>
		// 				<Tooltip
		// 					open={openTooltip === `budget-${cellValues?.id}`}
		// 					onOpen={(e) => checkOverflow(e) && setOpenTooltip(`budget-${cellValues?.id}`)}
		// 					onClose={() => setOpenTooltip(0)}
		// 					arrow
		// 					placement="top"
		// 					title={cellValues?.formattedValue}
		// 				>
		// 					<StyledWrapper.CardRowInfo
		// 						data-testid={`${cellValues?.field}-${cellValues?.id}`}
		// 						width={'80%'}
		// 					>
		// 						{cellValues?.formattedValue?.title || '-'}
		// 					</StyledWrapper.CardRowInfo>
		// 				</Tooltip>
		// 			</>
		// 		);
		// 	},
		// 	flex: 2,
		// 	minWidth: 100
		// },
		{
			field: 'ancestory',
			headerName: 'Program Type',
			description: 'Program  Type',
			renderCell: (cellValues) => {
				const isParent = cellValues.row?.is_parent && 'Parent Program';
				const isChild = cellValues.row?.ancestory && 'Child Program';

				return (
					<>
						<Tooltip
							open={openTooltip === `ancestry-${cellValues?.id}`}
							onOpen={(e) => checkOverflow(e) && setOpenTooltip(`ancestry-${cellValues?.id}`)}
							onClose={() => setOpenTooltip(0)}
							arrow
							placement="top"
							title={cellValues?.formattedValue}
						>
							<StyledWrapper.CardRowInfo
								data-testid={`${cellValues?.field}-${cellValues?.id}`}
								width={'80%'}
							>
								{isParent || isChild || '-'}
							</StyledWrapper.CardRowInfo>
						</Tooltip>
					</>
				);
			},
			flex: 1,
			minWidth: 100
		}
		// {
		// 	field: 'application_count',
		// 	headerName: 'Applications',
		// 	description: 'Applications',
		// 	type: 'number',
		// 	renderCell: (cellValues) => {
		// 		return (
		// 			<>
		// 				<Tooltip
		// 					open={openTooltip === `application_count-${cellValues?.id}`}
		// 					onOpen={(e) =>
		// 						checkOverflow(e) && setOpenTooltip(`application_count-${cellValues?.id}`)
		// 					}
		// 					onClose={() => setOpenTooltip(0)}
		// 					arrow
		// 					placement="top"
		// 					title={cellValues?.formattedValue}
		// 				>
		// 					<StyledWrapper.CardRowInfo
		// 						centerText
		// 						data-testid={`${cellValues?.field}-${cellValues?.id}`}
		// 						width={'80%'}
		// 					>
		// 						{cellValues?.formattedValue ? cellValues?.formattedValue : '--'}
		// 					</StyledWrapper.CardRowInfo>
		// 				</Tooltip>
		// 			</>
		// 		);
		// 	},
		// 	flex: 1,
		// 	headerAlign: 'left',
		// 	minWidth: 100
		// },
		// {
		// 	field: 'reviews_count',
		// 	headerName: 'Reviews',
		// 	description: 'Reviews',
		// 	type: 'number',
		// 	renderCell: (cellValues) => {
		// 		return (
		// 			<>
		// 				<Tooltip
		// 					open={openTooltip === `reviews_count-${cellValues?.id}`}
		// 					onOpen={(e) => checkOverflow(e) && setOpenTooltip(`reviews_count-${cellValues?.id}`)}
		// 					onClose={() => setOpenTooltip(0)}
		// 					arrow
		// 					placement="top"
		// 					title={cellValues?.formattedValue}
		// 				>
		// 					<StyledWrapper.CardRowInfo
		// 						centerText
		// 						data-testid={`${cellValues?.field}-${cellValues?.id}`}
		// 						width={'80%'}
		// 					>
		// 						{cellValues?.formattedValue ? cellValues?.formattedValue : '--'}
		// 					</StyledWrapper.CardRowInfo>
		// 				</Tooltip>
		// 			</>
		// 		);
		// 	},
		// 	flex: 1,
		// 	headerAlign: 'left',
		// 	minWidth: 100
		// }
		/* -------------------------------------- */
		/* This code is temporarily commented out */
		/* -------------------------------------- */
	];

	const [orderedColumns, setOrderedColumns] = useState(columns);

	const updateOrderedColumns = (updatedOrderedColumns) => setOrderedColumns(updatedOrderedColumns);

	const exportTableToCSV = async () => {
		let csv = '';

		// column headers
		csv += orderedColumns.map((column) => column.headerName).join(',') + '\n';
		try {
			const response = await getAllPrograms({
				page_size: pagination.rowCount,
				apiToken
			});

			if ('error' in response) {
				throw new Error('Error getting applications data:', response.error);
			}
			response.data.results.forEach((row) => {
				const rowData = [];

				orderedColumns.forEach((column) => {
					switch (column.field) {
						case 'title':
							// remove commas from program titles (can't have commas in a CSV file)
							rowData.push(row.title.replace(/,/g, '') + ' [p-' + row.programId + ']');
							break;
						case 'app_process_is_ready_to_publish':
							rowData.push(row.app_process_is_ready_to_publish ? 'Setup Finished' : 'Needs Setup');
							break;
						case 'ancestory':
							const isParent = row.is_parent && 'Parent Program';
							const isChild = row.ancestory && 'Child Program';
							rowData.push(isParent || isChild || '-');
							break;
						default:
							rowData.push(row[column.field]);
							break;
					}
				});

				csv += rowData.join(',') + '\n';
			});

			const blob = new Blob([csv], { type: 'text/csv' });
			const fileName = `programs-${new Date().toLocaleString()}.csv`;

			createAndDownloadFile(blob, fileName);
		} catch (error) {
			console.error(error);
		}
	};

	useEffect(() => {
		setNoPrograms(
			!pagination?.isUninitialized &&
				pagination?.rows &&
				pagination.rowCount <= 0 &&
				pagination.filterModel.length == 0
		);
	}, [pagination]);

	return (
		<div>
			<h4>Programs</h4>

			{noPrograms ? (
				<Stack sx={{ width: '100%', marginBottom: '20px' }} spacing={2}>
					<Alert variant="outlined" severity="info">
						No programs exist in the system.
						<br />
						All programs, once created, will appear on this page.
					</Alert>
				</Stack>
			) : (
				<>
					<StyledWrapper.Toolbar>
						<PrintActions marginBottom={'20px'} data-testid={`programs-actionButtons`} />
					</StyledWrapper.Toolbar>
					<Backdrop
						sx={{ zIndex: (theme) => theme.zIndex.drawer + 5 }}
						open={csvDataIsLoading || csvDataIsFetching}
					>
						<CircularProgress />
					</Backdrop>
					<Table
						getRowId={(cell) => cell?.programId}
						columns={columns}
						rowHeight={true}
						enableAutoPageSize
						rows={pagination?.rows?.results || []}
						rowCount={pagination?.rowCount}
						pagination={pagination?.pagination}
						pageSize={pagination?.pageSize}
						paginationMode={pagination?.paginationMode}
						onPageChange={(e) => pagination?.onPageChange(e)}
						onPageSizeChange={(e) => pagination?.onPageSizeChange(e)}
						page={pagination?.page}
						loading={pagination?.loading}
						sortingMode={pagination?.sortingMode}
						onSortModelChange={(e) => pagination?.onSortModelChange(e)}
						onFilterModelChange={(e) => pagination?.onFilterModelChange(e)}
						filterModel={pagination?.filterModel}
						sortModel={pagination?.sortModel}
						updateOrderedColumns={updateOrderedColumns}
						tableExportAvailable={tableExportAvailable}
						exportTableToCSV={exportTableToCSV}
					/>
				</>
			)}
		</div>
	);
};

export default Programs;

import { getOldBlueColor } from '../../utils/utilFunctions';

const LinkReviewIcon = ({ isActive, width, color }) => {
	return (
		<svg
			width={width || '30'}
			height={width || '30'}
			viewBox="0 0 21 19"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M16.0004 14.9443C14.3704 14.9443 12.8804 15.5043 11.6904 16.4343C12.5504 17.9243 14.1504 18.9443 16.0004 18.9443C17.8504 18.9443 19.4504 17.9243 20.3104 16.4343C19.1204 15.5043 17.6304 14.9443 16.0004 14.9443Z"
				fill={isActive ? color || getOldBlueColor() : 'rgba(0, 0, 0, 0.26)'}
			/>
			<path
				d="M18.0004 11.9443C18.0004 10.8443 17.1004 9.94434 16.0004 9.94434C14.9004 9.94434 14.0004 10.8443 14.0004 11.9443C14.0004 13.0443 14.9004 13.9443 16.0004 13.9443C17.1004 13.9443 18.0004 13.0443 18.0004 11.9443Z"
				fill={isActive ? color || getOldBlueColor() : 'rgba(0, 0, 0, 0.26)'}
			/>
			<path
				d="M7.0002 8.44434H13.0002C13.5502 8.44434 14.0002 8.89434 14.0002 9.44434C14.0002 9.99434 13.5502 10.4443 13.0002 10.4443H7.0002C6.4502 10.4443 6.0002 9.99434 6.0002 9.44434C6.0002 8.89434 6.4502 8.44434 7.0002 8.44434ZM18.9302 9.44434C19.5502 9.44434 20.0002 8.85434 19.8602 8.25434C19.3202 6.06434 17.3502 4.44434 15.0002 4.44434H11.9502C11.4302 4.44434 11.0002 4.87434 11.0002 5.39434C11.0002 5.91434 11.4302 6.34434 11.9502 6.34434H15.0002C16.4502 6.34434 17.6702 7.34434 18.0102 8.68434C18.1202 9.12434 18.4802 9.44434 18.9302 9.44434ZM1.9602 8.82434C2.2402 7.35434 3.6202 6.34434 5.1202 6.34434H8.0502C8.5702 6.34434 9.0002 5.91434 9.0002 5.39434C9.0002 4.87434 8.5702 4.44434 8.0502 4.44434H5.2202C2.6102 4.44434 0.280197 6.35434 0.0301974 8.95434C-0.259803 11.9343 2.0802 14.4443 5.0002 14.4443H8.0502C8.5702 14.4443 9.0002 14.0143 9.0002 13.4943C9.0002 12.9743 8.5702 12.5443 8.0502 12.5443H5.0002C3.0902 12.5443 1.5802 10.8043 1.9602 8.82434Z"
				fill={isActive ? color || getOldBlueColor() : 'rgba(0, 0, 0, 0.26)'}
			/>
		</svg>
	);
};

export default LinkReviewIcon;

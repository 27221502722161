import { getGNURL /* getPortalURL */ } from '../../../../utils/environmentUtils';
import { numberToMoney } from '../../../../utils/numberUtils';

export const generateFormSchema = (data) => {
	let fundingBy = [];

	data?.funded_by?.forEach((e) => {
		fundingBy?.push({
			field_name: `linkInput_${e?.projectID || e?.organizationFundingSourceId}`,
			created_at: '2023-01-31T18:25:05.789945Z',
			updated_at: '2023-01-31T18:25:05.789954Z',
			element: 'CustomElement',
			text: 'Link Input',
			group_name: 'Controls',
			static: true,
			form: '5d156c94-f515-4d9f-8a27-e7ab00d81428',
			custom: true,
			key: 'MUI_LinkInput',
			forward_ref: true,
			custom_options: ['hideLabel', 'hideIcon'],
			label: '<p>Funded by Project</p>',
			second_label: 'Link to Project',
			can_have_alternate_form: true,
			can_have_option_correct: true,
			can_populate_from_api: true,
			can_have_page_break_before: true,
			can_have_display_horizontal: true,
			can_have_option_value: true,
			id: `${e?.projectID || e?.organizationFundingSourceId}`
		});
	});

	return [
		{
			field_name: 'linkInput_478D8248-8384-4AA0-BC12-81311B121002',
			created_at: '2023-01-31T18:25:05.766188Z',
			updated_at: '2023-01-31T18:25:05.766205Z',
			element: 'CustomElement',
			text: 'Link Input',
			group_name: 'Controls',
			static: true,
			form: '5d156c94-f515-4d9f-8a27-e7ab00d81428',
			custom: true,
			key: 'MUI_LinkInput',
			forward_ref: true,
			custom_options: ['hideLabel'],
			label: '<p>Program Name &amp; Status</p>',
			second_label: 'Program External Link (Grants Network)',
			can_have_alternate_form: true,
			can_have_option_correct: true,
			can_populate_from_api: true,
			can_have_page_break_before: true,
			can_have_display_horizontal: true,
			can_have_option_value: true,
			id: '5a575b96-314c-4e23-8023-17c5a615d76a',
			chip: `${data?.status}`
		},
		{
			id: 'AFBEBE94-9565-4E2A-BF2A-A5BF772E0806',
			element: 'TwoColumnRow',
			text: 'Two Column Row',
			group_name: 'Layout',
			required: false,
			can_have_page_break_before: true,
			can_have_alternate_form: true,
			can_have_display_horizontal: true,
			can_have_option_correct: true,
			can_have_option_value: true,
			can_populate_from_api: true,
			field_name: 'two_col_row_E15C3512-687F-41DE-803F-037D329359F6',
			temp_order: 3,
			child_items: ['658FDB74-C5B3-43E8-9D38-3AAA789381E8', 'EF7FA3AD-E08E-47F1-944D-CF4EBF03D849'],
			is_container: true
		},
		{
			id: '658FDB74-C5B3-43E8-9D38-3AAA789381E8',
			element: 'CustomElement',
			text: 'Single Line Input',
			group_name: 'Controls',
			required: false,
			key: 'MUI_Input',
			custom: true,
			forward_ref: true,
			custom_options: ['hideLabel'],
			can_have_page_break_before: true,
			can_have_alternate_form: true,
			can_have_display_horizontal: true,
			can_have_option_correct: true,
			can_have_option_value: true,
			can_populate_from_api: true,
			field_name: 'mui_input_7A4F910F-CA42-4500-A56B-5F7C3B212481',
			label: '<p>Requested Funds</p>\n',
			temp_order: 2,
			field_id: '658FDB74-C5B3-43E8-9D38-3AAA789381E8',
			col: 0,
			parent_id: 'AFBEBE94-9565-4E2A-BF2A-A5BF772E0806',
			parent_index: -1
		},
		{
			id: 'EF7FA3AD-E08E-47F1-944D-CF4EBF03D849',
			element: 'CustomElement',
			text: 'Single Line Input',
			group_name: 'Controls',
			required: false,
			key: 'MUI_Input',
			custom: true,
			forward_ref: true,
			custom_options: ['hideLabel'],
			can_have_page_break_before: true,
			can_have_alternate_form: true,
			can_have_display_horizontal: true,
			can_have_option_correct: true,
			can_have_option_value: true,
			can_populate_from_api: true,
			field_name: 'mui_input_70EE8E15-9943-440B-8163-67A41BDFEA03',
			label: '<p>Available Funds</p>\n',
			temp_order: 3,
			field_id: 'EF7FA3AD-E08E-47F1-944D-CF4EBF03D849',
			col: 1,
			parent_id: 'AFBEBE94-9565-4E2A-BF2A-A5BF772E0806',
			parent_index: -1
		},
		{
			id: 'A33AEAEC-44C5-47B3-B8D2-962BC06AA840',
			element: 'CustomElement',
			text: 'Start/End Date',
			group_name: 'Controls',
			required: false,
			key: 'MUI_StartEndDate',
			custom: true,
			forward_ref: true,
			custom_options: ['min_date', 'max_date', 'hideLabel', 'hideTimePeriod'],
			can_have_page_break_before: true,
			can_have_alternate_form: true,
			can_have_display_horizontal: true,
			can_have_option_correct: true,
			can_have_option_value: true,
			can_populate_from_api: true,
			field_name: 'mui_startend_D9FFD4F0-51C7-4E27-90C1-79850E01143C',
			label: 'Application Period',
			hideHelperText: true
		},
		{
			field_name: 'linkInput_BC4055FF-E92F-40D8-9F96-97E1D8D08A26',
			created_at: '2023-01-31T18:25:05.776726Z',
			updated_at: '2023-01-31T18:25:05.776734Z',
			element: 'CustomElement',
			text: 'Link Input',
			group_name: 'Controls',
			static: true,
			form: '5d156c94-f515-4d9f-8a27-e7ab00d81428',
			custom: true,
			key: 'MUI_LinkInput',
			forward_ref: true,
			custom_options: ['hideLabel', 'editOnlyLink'],
			label: '<p>Solicitation Listing</p>',
			second_label: 'Solicitation Listing Link',
			can_have_alternate_form: true,
			can_have_option_correct: true,
			can_populate_from_api: true,
			can_have_page_break_before: true,
			can_have_display_horizontal: true,
			can_have_option_value: true,
			id: '71a6993c-64c2-4daf-95de-e8a4ce6fb669'
		},
		// {
		// 	field_name: 'linkInput_4D3475D8-9D5F-4D55-B954-6F74981FDE53',
		// 	created_at: '2023-01-31T18:25:05.779961Z',
		// 	updated_at: '2023-01-31T18:25:05.779970Z',
		// 	element: 'CustomElement',
		// 	text: 'Link Input',
		// 	group_name: 'Controls',
		// 	static: true,
		// 	form: '5d156c94-f515-4d9f-8a27-e7ab00d81428',
		// 	custom: true,
		// 	key: 'MUI_LinkInput',
		// 	forward_ref: true,
		// 	custom_options: ['hideLabel'],
		// 	label: '<p>Budget Template</p>',
		// 	second_label: 'Budget Template Link',
		// 	can_have_alternate_form: true,
		// 	can_have_option_correct: true,
		// 	can_populate_from_api: true,
		// 	can_have_page_break_before: true,
		// 	can_have_display_horizontal: true,
		// 	can_have_option_value: true,
		// 	id: '0d825fd2-ec66-409d-b498-13e91fb75833'
		// },

		{
			field_name: 'mui_input_4D3475D8-9D5F-4D55-B954-6F74981FDE53',
			created_at: '2023-01-31T18:25:05.783174Z',
			updated_at: '2023-01-31T18:25:05.783182Z',
			element: 'CustomElement',
			text: 'Single Line Input',
			group_name: 'Controls',
			static: true,
			form: '5d156c94-f515-4d9f-8a27-e7ab00d81428',
			custom: true,
			key: 'MUI_Input',
			forward_ref: true,
			custom_options: ['hideLabel'],
			label: '<p>Budget Template</p>',
			can_have_alternate_form: true,
			can_have_option_correct: true,
			can_populate_from_api: true,
			can_have_page_break_before: true,
			can_have_display_horizontal: true,
			can_have_option_value: true,
			id: 'a64cdf24-b59f-4067-b2bf-af16a1601d5g'
		},
		{
			field_name: 'mui_input_36960E21-39C4-4BC8-B1D8-E3F939EDF88B',
			created_at: '2023-01-31T18:25:05.783174Z',
			updated_at: '2023-01-31T18:25:05.783182Z',
			element: 'CustomElement',
			text: 'Single Line Input',
			group_name: 'Controls',
			static: true,
			form: '5d156c94-f515-4d9f-8a27-e7ab00d81428',
			custom: true,
			key: 'MUI_Input',
			forward_ref: true,
			custom_options: ['hideLabel'],
			label: '<p>Program Type</p>',
			can_have_alternate_form: true,
			can_have_option_correct: true,
			can_populate_from_api: true,
			can_have_page_break_before: true,
			can_have_display_horizontal: true,
			can_have_option_value: true,
			id: 'a64cdf24-b59f-4067-b2bf-af16a1601d5f'
		},
		{
			field_name: 'mui_groupingStart_475CDDE2-CD63-460B-BCB9-491545E328FE',
			created_at: '2023-01-31T18:25:05.786579Z',
			updated_at: '2023-01-31T18:25:05.786588Z',
			element: 'CustomElement',
			text: 'Grouping Start',
			group_name: 'Layout',
			static: true,
			form: '5d156c94-f515-4d9f-8a27-e7ab00d81428',
			custom: true,
			key: 'MUI_GroupingStart',
			forward_ref: true,
			custom_options: ['hideLabel'],
			label: '',
			can_have_alternate_form: true,
			can_have_option_correct: true,
			can_populate_from_api: true,
			can_have_page_break_before: true,
			can_have_display_horizontal: true,
			can_have_option_value: true,
			id: 'b268e321-e7e1-45bc-9fdc-97d8d98f1ae0'
		},
		...fundingBy,
		{
			field_name: 'mui_groupingEnd_BB23E0A7-CD22-4F4C-BE6A-E371F21B89FD',
			created_at: '2023-01-31T18:25:05.803849Z',
			updated_at: '2023-01-31T18:25:05.803858Z',
			element: 'CustomElement',
			text: 'Grouping End',
			group_name: 'Layout',
			static: true,
			form: '5d156c94-f515-4d9f-8a27-e7ab00d81428',
			custom: true,
			key: 'MUI_GroupingEnd',
			forward_ref: true,
			custom_options: ['hideLabel'],
			label: 'Grouping End',
			can_have_alternate_form: true,
			can_have_option_correct: true,
			can_populate_from_api: true,
			can_have_page_break_before: true,
			can_have_display_horizontal: true,
			can_have_option_value: true,
			id: 'f2b10faf-45e8-47c9-a9a1-4cc5ae7d0d8f'
		},
		{
			field_name: 'linkInput_57809EBF-A294-40BB-8970-C43AF7F96DC3',
			created_at: '2023-01-31T18:25:05.807386Z',
			updated_at: '2023-01-31T18:25:05.807394Z',
			element: 'CustomElement',
			text: 'Link Input',
			group_name: 'Controls',
			static: true,
			form: '5d156c94-f515-4d9f-8a27-e7ab00d81428',
			custom: true,
			key: 'MUI_LinkInput',
			forward_ref: true,
			custom_options: ['hideLabel'],
			label: '<p>Reports</p>',
			second_label: 'Reports Link',
			can_have_alternate_form: true,
			can_have_option_correct: true,
			can_populate_from_api: true,
			can_have_page_break_before: true,
			can_have_display_horizontal: true,
			can_have_option_value: true,
			id: 'fca67ecc-3e1e-458c-bb3d-889002192ba5'
		}
	];
};

export const formatDetailAnswers = (data) => {
	let fundingBy = [];
	data?.funded_by?.forEach((e) => {
		fundingBy?.push({
			name: `linkInput_${e?.projectID || e?.organizationFundingSourceId}`,
			custom_name: `linkInput_${e?.projectID}`,
			value: [
				e?.accountTitle || e?.extGrantName || `Unspecified Project`,
				e?.projectID
					? `${getGNURL()}/GO/b_projViewProjectOverview/PROJECTID/${e?.projectID}`
					: `${getGNURL()}/GO/b_orgFundingEditForm/sourceid/${e?.organizationFundingSourceId}`
			]
		});
	});

	const ancestryObject = data?.[0] || data;
	return [
		{
			name: 'linkInput_478D8248-8384-4AA0-BC12-81311B121002',
			custom_name: 'linkInput_478D8248-8384-4AA0-BC12-81311B121002',
			value: [data?.title || '', `/programs/${data?.programId || ' '}/overview`]
		},
		{
			name: 'mui_input_7A4F910F-CA42-4500-A56B-5F7C3B212481',
			custom_name: 'mui_input_7A4F910F-CA42-4500-A56B-5F7C3B212481',
			value: data?.requested_funds ? `${numberToMoney(data?.requested_funds)}` : '$0.00'
		},
		{
			name: 'mui_input_70EE8E15-9943-440B-8163-67A41BDFEA03',
			custom_name: 'mui_input_70EE8E15-9943-440B-8163-67A41BDFEA03',
			value: data?.available_funds ? `${numberToMoney(data?.available_funds)}` : '$0.00'
		},
		{
			name: 'mui_startend_D9FFD4F0-51C7-4E27-90C1-79850E01143C',
			custom_name: 'mui_startend_D9FFD4F0-51C7-4E27-90C1-79850E01143C',
			value: [data?.start_date, data?.end_date]
		},
		{
			name: 'linkInput_BC4055FF-E92F-40D8-9F96-97E1D8D08A26',
			custom_name: 'linkInput_BC4055FF-E92F-40D8-9F96-97E1D8D08A26',
			value: [
				`${getGNURL()}/GO/srmng_solicitationEdit/SOLICITATIONID/${data?.solicitationId}`,
				`${getGNURL()}/GO/srmng_solicitationEdit/SOLICITATIONID/${data?.solicitationId}`
			]
		},
		// {
		// 	name: 'linkInput_4D3475D8-9D5F-4D55-B954-6F74981FDE53',
		// 	custom_name: 'linkInput_4D3475D8-9D5F-4D55-B954-6F74981FDE53',
		// 	value: [
		// 		data?.budget_template?.[0]?.title || data?.budget_template?.title,
		// 		`${getPortalURL()}/#/peerBudget/${data?.budget_template?.[0]?.budgetTemplateID}`
		// 	]
		// },
		{
			name: 'mui_input_4D3475D8-9D5F-4D55-B954-6F74981FDE53',
			custom_name: 'mui_input_4D3475D8-9D5F-4D55-B954-6F74981FDE53',
			value: data?.budget_template?.[0]?.title || data?.budget_template?.title || 'N/A'
		},
		{
			name: 'mui_input_36960E21-39C4-4BC8-B1D8-E3F939EDF88B',
			custom_name: 'mui_input_36960E21-39C4-4BC8-B1D8-E3F939EDF88B',
			value:
				ancestryObject?.ancestry?.title || ancestryObject?.ancestory?.title
					? 'Child Program'
					: data?.is_parent
					? 'Parent Program'
					: 'N/A'
		},
		{
			name: 'mui_groupingStart_475CDDE2-CD63-460B-BCB9-491545E328FE',
			custom_name: 'mui_groupingStart_475CDDE2-CD63-460B-BCB9-491545E328FE',
			value: ''
		},
		...fundingBy,
		{
			name: 'linkInput_57809EBF-A294-40BB-8970-C43AF7F96DC3',
			custom_name: 'linkInput_57809EBF-A294-40BB-8970-C43AF7F96DC3',
			value: ['Grants Network Enterprise Reports', `${getGNURL()}/GO/rpt_enterpriseReporting`]
		}
	];
};

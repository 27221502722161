import { useEffect, useCallback, useState } from 'react';
import Box from '@mui/material/Box';
import {
	DataGridPremium,
	useGridApiRef,
	useKeepGroupedColumnsHidden
} from '@mui/x-data-grid-premium';
import { TableFilters } from '../TableFilters/TableFilters';
import TableActions from '../TableActions/TableActions';
import StyledWrapper from './style';

export default function Table(props) {
	const {
		rows,
		columns,
		minWidth,
		rowHeight,
		customGetRowHeight,
		columnBuffer,
		rowGroupingModel,
		rowHover,
		checkboxSelection,
		onSelectChange,
		isRowSelectable,
		isRowSelected,
		keepNonExistentRowsSelected,
		rowSelectionModel,
		onRowSelectionModelChange,
		customHeight,
		marginTop,
		groupingColDef,
		disableSelectionOnClick,
		treeData,
		getTreeDataPath,
		getRowId,
		customRowID,
		rowCount,
		pageSize,
		onPageSizeChange,
		style,
		rowReordering,
		disableReorder,
		onRowOrderChange,
		disablePagination,
		paginationMode = 'client',
		onPageChange,
		page,
		loading,
		enableAutoPageSize,
		onColumnResize,
		sortingMode,
		onSortModelChange,
		setHiddenColumns,
		hideFilters,
		setOuterApiRef,
		key,
		onFilterModelChange,
		hideDisabledCheckboxes,
		addGroupDividers,
		filterModel,
		sortModel,
		hideTableActions = false,
		emailType,
		updateOrderedColumns,
		exportTableToCSV,
		exportTableToPDF,
		tableExportAvailable,
		emailEndpoint,
		disableRowGrouping = true,
		isOpenedFilter = false,
		excludeColumns = [],
		moveCheckboxColumn = false,
		rowsPerPageOptions = []
	} = props;

	const apiRef = useGridApiRef();

	const [finalColumns, setFinalColumns] = useState(columns);
	const [visibleColumns, setVisibleColumns] = useState([]);
	const customStyle = {
		'& .MuiDataGrid-cell:focus': {
			outline: 'none'
		},
		'& .MuiDataGrid-cell:focus-within': {
			outline: 'none'
		},
		'& .MuiDataGrid-groupingCriteriaCellToggle': {
			marginRight: '0px'
		},
		'& .groupedRow': {
			backgroundColor: 'rgba(217, 217, 217, 0.26)'
		},
		'& .borderedRow': {
			borderTop: '1px solid rgba(0,0,0,0.5)'
		},
		'& .groupedStart': {
			borderTop: '1px solid rgba(0, 0, 0, 0.54)'
		},
		'& .MuiDataGrid-columnHeader[aria-label="Group"]': {
			display: 'none !important'
		},
		'& .MuiDataGrid-cell[data-field="__tree_data_group__"]': {
			padding: '0 !important'
		},
		'& .MuiDataGrid-cellCheckbox .Mui-disabled': {
			display: hideDisabledCheckboxes ? 'none !important' : 'block'
		}
	};

	const handleFilterChange = useCallback(
		(newFilterOptions) => {
			if (onFilterModelChange) onFilterModelChange(newFilterOptions?.items ?? []);
		},
		[onFilterModelChange]
	);

	const handleSortChange = useCallback(
		(newSortOptions) => {
			if (onSortModelChange) onSortModelChange(newSortOptions);
		},
		[onSortModelChange]
	);

	useEffect(() => {
		hideColumns(setHiddenColumns);
	}, [setHiddenColumns]);

	useEffect(() => {
		setOuterApiRef && setOuterApiRef(apiRef);
		setVisibleColumns(
			apiRef?.current
				?.getVisibleColumns()
				?.map((e) => e?.field)
				.filter((column) => !['__check__', '__tree_data_group__'].includes(column)) ?? []
		);

		if (moveCheckboxColumn) {
			apiRef?.current?.setColumnIndex('__check__', 1);
		}
	}, [apiRef]);

	const onRowClick = useCallback(
		(params) => {
			const rowNode = apiRef.current.getRowNode(params.id);
			if (rowNode && rowNode.isAutoGenerated) {
				apiRef.current.setRowChildrenExpansion(params.id, !rowNode.childrenExpanded);
			}
		},
		[apiRef]
	);

	const initialState = useKeepGroupedColumnsHidden({
		apiRef,
		rowGroupingModel
	});

	const onRowHover = (e) => {
		const rowId = Number(e.currentTarget.dataset.id);
		rowHover && rowHover(rowId);
	};

	const hideColumns = (hiddenColumns = []) => {
		apiRef.current
			.getAllColumns()
			.map((c) => c.field)
			.forEach((c) => {
				const isVisible = !hiddenColumns.includes(c);
				apiRef.current.setColumnVisibility(c, isVisible);
			});
	};

	const handleFilterSave = (filter) => {
		onFilterModelChange && onFilterModelChange(filter);
	};

	const resetColumnFilters = () => {
		onFilterModelChange && onFilterModelChange([]);
		onSortModelChange && onSortModelChange([]);
		columns.forEach((colume) => apiRef.current.setColumnVisibility(colume.field, true));
	};

	const getRowHeight = () => (!rowHeight ? 'auto' : 52);

	return (
		<Box
			sx={{
				height: customHeight || `80vh`,
				width: '100%',
				minWidth,
				display: 'flex',
				marginTop: marginTop ?? '30px'
			}}
			key={key}
			className={disableReorder && 'disableTableRows'}
		>
			<DataGridPremium
				disableColumnFilter
				disableColumnSelector
				disableRowGrouping={disableRowGrouping}
				initialState={{
					...initialState,
					sorting: {
						sortModel: sortModel ?? []
					},
					filter: {
						filterModel: filterModel ?? []
					}
				}}
				sortingMode={sortingMode}
				onSortModelChange={handleSortChange}
				onColumnResize={onColumnResize}
				checkboxSelection={checkboxSelection}
				onSelectionModelChange={(ids) => {
					const selectedIDs = new Set(ids);
					const selectedRowData = rows.filter((row) => {
						const currentID = customRowID ? row?.[customRowID] : row?.id;
						return selectedIDs?.has(currentID?.toString()) || selectedIDs.has(currentID);
					});
					onSelectChange && onSelectChange(selectedRowData);
				}}
				sortModel={sortModel}
				defaultGroupingExpansionDepth={-1}
				groupingColDef={groupingColDef}
				isRowSelectable={isRowSelectable}
				isRowSelected={isRowSelected}
				keepNonExistentRowsSelected={keepNonExistentRowsSelected}
				rowSelectionModel={rowSelectionModel}
				onRowSelectionModelChange={onRowSelectionModelChange}
				apiRef={apiRef}
				onRowClick={onRowClick}
				rows={rows}
				filterModel={{
					items: filterModel ?? [],
					linkOperator: 'and',
					quickFilterLogicOperator: 'and',
					quickFilterValues: []
				}}
				onFilterModelChange={handleFilterChange}
				filterMode={onFilterModelChange ? 'server' : 'client'}
				rowReordering={rowReordering}
				onRowOrderChange={onRowOrderChange}
				rowCount={rowCount}
				getRowId={getRowId}
				columns={finalColumns}
				disableSelectionOnClick={disableSelectionOnClick}
				rowGroupingModel={rowGroupingModel}
				experimentalFeatures={{ newEditingApi: true }}
				pagination={!disablePagination}
				autoPageSize={enableAutoPageSize}
				columnBuffer={columnBuffer || 1000}
				style={{ ...style, border: '0px' }}
				getRowHeight={customGetRowHeight ? customGetRowHeight : getRowHeight}
				sx={customStyle}
				componentsProps={{
					row: {
						onMouseEnter: (e) => onRowHover(e) // <------- Here
					}
				}}
				treeData={treeData}
				getTreeDataPath={getTreeDataPath}
				pageSize={pageSize}
				onPageSizeChange={onPageSizeChange}
				rowsPerPageOptions={rowsPerPageOptions}
				paginationMode={paginationMode}
				onPageChange={onPageChange}
				page={page}
				loading={loading}
				getRowClassName={(params) => {
					if (params?.row?.hierarchy?.length === 1 && addGroupDividers) {
						return 'groupedStart';
					}
					if (params?.row?.type?.includes('Application Instance')) {
						return 'groupedRow';
					}
					if (
						!params.row?.status &&
						(params.row?.type?.includes('APPLICATION PROCESS') ||
							params.row?.type?.includes('Submission Stage') ||
							params.row?.type?.includes('Evaluation Stage'))
					) {
						return 'borderedRow';
					}
				}}
				onColumnVisibilityModelChange={(_) => {
					setVisibleColumns(
						apiRef?.current
							?.getVisibleColumns()
							?.map((e) => e?.field)
							.filter((column) => !['__check__', '__tree_data_group__'].includes(column))
					);
				}}
			/>

			<StyledWrapper.ActionsContainer>
				{!hideTableActions && (
					<TableActions
						activeFilters={filterModel}
						emailType={emailType}
						tableExportAvailable={tableExportAvailable}
						exportTableToCSV={exportTableToCSV}
						exportTableToPDF={exportTableToPDF}
						emailEndpoint={emailEndpoint}
						rowCount={rowCount}
					/>
				)}
				{!hideFilters && (
					<TableFilters
						handleFilterSave={handleFilterSave}
						resetColumnFilters={resetColumnFilters}
						updateOrderedColumns={updateOrderedColumns}
						apiRef={apiRef}
						columns={finalColumns.filter((column) => !excludeColumns.includes(column.field))}
						allColumns={finalColumns}
						setColumns={setFinalColumns}
						activeFilters={filterModel}
						visibleColumns={visibleColumns}
						isOpened={isOpenedFilter}
					/>
				)}
			</StyledWrapper.ActionsContainer>
		</Box>
	);
}

import { useState, useEffect } from 'react';

import { Table } from '../../../components/Table';

import { ApplicationToReviewColumns } from './appToRevColumns';
import { useGetAppToRevTable } from '../../../hooks/useGetAppToRevTable';

const EvalToRevTable = ({ alertBalloon, setChosenRows, hasEditPermissions }) => {
	const [columnWidth, setColumnWidth] = useState({});
	const appToRevColumns = ApplicationToReviewColumns(columnWidth);
	const [reload, setReload] = useState(false);

	const { data, pagination } = useGetAppToRevTable({ sortedByReviewer: false });

	useEffect(() => {
		if (alertBalloon?.status === 'success') {
			setReload(true);
			setTimeout(function () {
				setReload(false);
			}, 10);
		}
	}, [alertBalloon]);

	return (
		<>
			{!reload && (
				<Table
					columns={appToRevColumns}
					rowHeight={true}
					enableAutoPageSize
					rows={data || []}
					rowCount={pagination?.rowCount}
					pagination={pagination?.pagination}
					pageSize={pagination?.pageSize}
					paginationMode={pagination?.paginationMode}
					onPageChange={(e) => pagination?.onPageChange(e)}
					onPageSizeChange={(e) => pagination?.onPageSizeChange(e)}
					page={pagination?.page}
					loading={pagination?.loading}
					sortingMode={pagination?.sortingMode}
					onSortModelChange={(e) => pagination?.onSortModelChange(e)}
					onFilterModelChange={(e) => pagination?.onFilterModelChange(e)}
					checkboxSelection={hasEditPermissions}
					isRowSelectable={(params) =>
						params?.row?.stageId &&
						(params.row.appStatus.includes('In Progress') || params.row.appStatus === 'Not Started')
					}
					onSelectChange={(e) => setChosenRows(e)}
					disableSelectionOnClick
					key={'evalToRev-table'}
					onColumnResize={(e) => {
						const newColumnSizes = e?.colDef?.field;
						const width = e?.width;
						setColumnWidth({ ...columnWidth, [newColumnSizes]: width });
					}}
					filterModel={pagination?.filterModel}
					sortModel={pagination?.sortModel}
					hideTableActions={true}
					isOpenedFilter={true}
				/>
			)}
		</>
	);
};

export default EvalToRevTable;

import { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import StyledWrapper from './style';

import AssignReviewColumns from './AssignReviewColumns';
import { ItemPageTitle } from '../ItemPageTitle';
import { Table } from '../Table';
import ReviewStageIcon from '../../assets/customIcons/reviewStageIcon';
import {
	useBulkAssignReviewMutation,
	useGetAvailableReviewersQuery
} from '../../services/endpoints/reviewEndpoints';
import usePaginatedCall from '../../hooks/usePaginatedCall';
import { getLocalAuth } from '../../utils/environmentUtils';

import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { Button, Alert, Stack, Tooltip, Modal, Box, IconButton } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import LinkReviewIcon from '../../assets/customIcons/linkReviewIcon';
import ReviewFormIcon from '../../assets/customIcons/reviewForm';

export const BulkEvalAssignmentsModal = ({
	openModal,
	closeModal,
	item,
	chosenStages,
	handleAlert
}) => {
	const accountSource = useSelector((state) => state?.login?.accountSource);
	const apiToken = getLocalAuth(accountSource)?.access_token;
	const finalPagination = usePaginatedCall(useGetAvailableReviewersQuery, {
		filterBaseName: 'BULK_EVAL_ASSIGNMENTS'
	});
	const [bulkAssign] = useBulkAssignReviewMutation();
	const [open, setOpen] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [reviewersToAssign, setReviewersToAssign] = useState([]);
	const [reviewerPool, setReviewerPool] = useState([]);
	const selectedReviewersState = useRef();
	selectedReviewersState.current = reviewersToAssign;
	const reviewerPoolState = useRef();
	reviewerPoolState.current = reviewerPool;

	const handleReviewerSelect = (row) => {
		const foundIndex = selectedReviewersState.current?.findIndex(
			(e) => e?.reviewer_id === row?.reviewer_id
		);
		if (foundIndex >= 0) {
			// If the reviewer_id exists in reviewersToAssign, remove it and add it back to reviewerPool
			const updatedReviewers = selectedReviewersState.current.filter(
				(id) => id?.reviewer_id !== row?.reviewer_id
			);
			setReviewersToAssign(updatedReviewers);
			setReviewerPool([...reviewerPoolState.current, row]);
		} else {
			// If the reviewer_id doesn't exist in reviewersToAssign, add it and remove it from reviewerPool
			const updatedReviewers = [...selectedReviewersState.current, row];
			setReviewersToAssign(updatedReviewers);
			setReviewerPool(
				reviewerPoolState.current?.filter((id) => id?.reviewer_id !== row?.reviewer_id)
			);
		}
	};

	const AssignedColumns = AssignReviewColumns(
		false,
		reviewersToAssign,
		() => {},
		'Assigned',
		item?.appId,
		handleReviewerSelect,
		true
	);
	const ReviewPoolColumns = AssignReviewColumns(
		false,
		reviewerPool,
		() => {},
		'Reviewer Pool',
		item?.appId,
		handleReviewerSelect,
		true
	);

	const getPageTitle = () => {
		const pageTitle = (
			<div style={{ display: 'flex', gap: '6px' }}>
				<div>
					<ReviewStageIcon />
				</div>
				{!chosenStages ? (
					`Assignments of Evaluation Stage: ${item?.title} [${item?.appId}]`
				) : (
					<>
						{`Bulk assign the selected evaluation stage(s):`}
						<br></br>
						{chosenStages?.map((e, index) => {
							return (
								<>
									{`${e?.stageName}`}
									{index !== chosenStages?.length - 1 && ', '}
								</>
							);
						})}
					</>
				)}
			</div>
		);
		return pageTitle;
	};

	const handleClose = () => {
		closeModal && closeModal();
		setOpen(false);
	};

	const handleSubmit = async () => {
		setIsLoading(true);
		const payload = {
			reviewers: reviewersToAssign?.map((e) => e?.reviewer_id),
			stages: chosenStages?.map((e) => e?.stageId?.replace('esi-', ''))
		};

		const options = {
			payload,
			apiToken
		};

		await bulkAssign(options)
			.unwrap()
			.then(() => handleAlert('Bulk Assigned Succesfully!', 'success'))
			.catch(() => handleAlert('Bulk Assigned Failed!', 'error'));
		setIsLoading(false);
		handleClose();
	};

	const modalBoxStyle = {
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		width: '90%',
		bgcolor: 'background.paper',
		borderRadius: '4px',
		boxShadow:
			'0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12)',
		p: 4
	};

	useEffect(() => {
		setOpen(openModal);
	}, [openModal]);

	useEffect(() => {
		if (finalPagination?.rows?.results) {
			setReviewerPool(finalPagination.rows.results);
		}
	}, [finalPagination?.rows?.results]);

	const SubmitButton = () => (
		<LoadingButton
			loading={isLoading}
			disabled={isLoading || reviewersToAssign?.length === 0}
			onClick={handleSubmit}
			variant="contained"
			size="large"
			style={{ gap: 5 }}
		>
			Assign
			<LinkReviewIcon
				isActive={!(isLoading || reviewersToAssign?.length === 0)}
				color={'#FFFFFF'}
			/>
			<ReviewFormIcon
				isActive={!(isLoading || reviewersToAssign?.length === 0)}
				isBlueprint
				status={'White'}
			/>
		</LoadingButton>
	);

	return (
		<>
			{!isLoading && (
				<Modal
					open={open}
					onClose={handleClose}
					aria-labelledby="modal-modal-title"
					aria-describedby="modal-modal-description"
					sx={{ maxHeight: '90vh' }}
				>
					<Box sx={modalBoxStyle}>
						<StyledWrapper.OutermostContainer>
							<StyledWrapper.BackIconContainer>
								<IconButton
									onClick={handleClose}
									aria-label="delete"
									size="large"
									style={{ fontSize: '26px', background: '#E0E0E0' }}
								>
									<KeyboardBackspaceIcon style={{ fontSize: '26px' }} />
								</IconButton>
							</StyledWrapper.BackIconContainer>
							<ItemPageTitle
								removeGrayLine
								text={getPageTitle() || ''}
								style={{ justifyContent: 'left', paddingLeft: '55px' }}
							/>
							<StyledWrapper.InnerContainer padding={'0px 55px'} margin={'10px 0px 0px 0px'}>
								<ItemPageTitle text={chosenStages ? 'Assigning List' : 'Assigned'} />
								{reviewersToAssign?.length === 0 ? (
									<Stack sx={{ width: '100%', marginTop: '20px' }} spacing={2}>
										<Alert variant="outlined" severity="info">
											To assign reviewers, select them first from the list below.
											<br />
											Clicking on the plus sign will move them here. From there, you can proceed to
											assign them.
										</Alert>
									</Stack>
								) : (
									<Table
										disableSelectionOnClick
										getRowId={(cell) => cell?.reviewer_id}
										columns={AssignedColumns}
										rowHeight={true}
										customHeight={'25vh'}
										marginTop={'5px'}
										enableAutoPageSize
										rows={reviewersToAssign || []}
									/>
								)}
								<ItemPageTitle text={'Reviewers Pool'} />
								{finalPagination?.rows?.results == 0 ? (
									<Stack sx={{ width: '100%', marginTop: '20px' }} spacing={2}>
										<Alert variant="outlined" severity="info">
											There are no people added in the reviewers pool.
											<br />
											First, you have to go to the Grants Network portal and add a few.
										</Alert>
									</Stack>
								) : (
									<Table
										columns={ReviewPoolColumns}
										enableAutoPageSize
										rowHeight={true}
										disableSelectionOnClick
										getRowId={(cell) => cell?.reviewer_id}
										customHeight={'25vh'}
										marginTop={'5px'}
										rows={finalPagination?.rows?.results || []}
										rowCount={finalPagination?.rowCount}
										pagination={finalPagination?.pagination}
										pageSize={finalPagination?.pageSize}
										paginationMode={finalPagination?.paginationMode}
										onPageChange={(e) => finalPagination?.onPageChange(e)}
										onPageSizeChange={(e) => finalPagination?.onPageSizeChange(e)}
										page={finalPagination?.page}
										loading={finalPagination?.loading}
									/>
								)}
							</StyledWrapper.InnerContainer>
						</StyledWrapper.OutermostContainer>

						<StyledWrapper.ButtonContainer>
							<Button onClick={handleClose} variant="outlined" size="small">
								CANCEL
							</Button>
							{isLoading || reviewersToAssign?.length === 0 ? (
								<Tooltip title="Nothing to assign yet" placement="top">
									<span>
										<SubmitButton />
									</span>
								</Tooltip>
							) : (
								<SubmitButton />
							)}
						</StyledWrapper.ButtonContainer>
					</Box>
				</Modal>
			)}
		</>
	);
};

import { checkOverflow } from '../../../utils/utilFunctions';
import StyledWrapper from '../style';

import { Tooltip } from '@mui/material';

const BasicCellText = ({
	cellValues,
	openTooltip,
	setOpenTooltip,
	StartIcon,
	isLink,
	isDisabled
}) => (
	<Tooltip
		open={openTooltip === `${cellValues?.field}-${cellValues?.id}`}
		onOpen={(e) => checkOverflow(e) && setOpenTooltip(`${cellValues?.field}-${cellValues?.id}`)}
		onClose={() => setOpenTooltip(0)}
		arrow
		placement="top"
		title={cellValues?.formattedValue || 'n/a'}
	>
		<StyledWrapper.CellContainer
			data-testid={`cellContainer-${cellValues?.field}-${cellValues?.id}`}
		>
			{cellValues?.formattedValue && StartIcon && <StartIcon />}
			{!isLink ? (
				<StyledWrapper.CardRowInfo
					width={'100%'}
					status={isDisabled}
					data-testid={`rowInfo-${cellValues?.field}-${cellValues?.id}`}
				>
					{cellValues?.formattedValue || ''}
				</StyledWrapper.CardRowInfo>
			) : (
				<StyledWrapper.CardRowInfoLink
					width={'100%'}
					status={isDisabled}
					data-testid={`rowLink-${cellValues?.field}-${cellValues?.id}`}
				>
					{cellValues?.formattedValue || ''}
				</StyledWrapper.CardRowInfoLink>
			)}
		</StyledWrapper.CellContainer>
	</Tooltip>
);

export default BasicCellText;

import React, { useState, useEffect, useMemo } from 'react';

import { getChipColor, removeHttpVariations } from '../../../utils/utilFunctions';
import { Registry } from '../../FormBuilderLibrary';
import { stringToHTML } from '../utils';

import StyledWrapper from './style';

import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import FormHelperText from '@mui/material/FormHelperText';
import LaunchIcon from '@mui/icons-material/Launch';
import Chip from '@mui/material/Chip';
import FormControl from '@mui/material/FormControl';
import { getElementIcon } from '../utils';

export const MUILinkInput = React.forwardRef((props, ref) => {
	const { name, defaultValue, disabled, isFormBuilder } = props;
	const [hasError, setHasError] = useState(props?.data?.error || false);

	function areAllEmptyStrings(array) {
		return array?.every((entry) => entry === '');
	}

	const allEmpty = defaultValue ? areAllEmptyStrings(defaultValue) : true;

	const parsedLabel = useMemo(() => stringToHTML(props?.data?.label), [props?.data?.label]);
	const parsedHelperText = useMemo(
		() => stringToHTML(props?.data?.help_message),
		[props?.data?.help_message]
	);

	useEffect(() => {
		setHasError(props?.data?.error || false);
	}, [props?.data?.error]);
	const handleChange = () => {
		setHasError(false);
	};

	const styleForNotFormBuilder = !isFormBuilder
		? { width: '100%', marginTop: '16px' }
		: { width: '100%' };
	return (
		<>
			{disabled ? (
				<StyledWrapper.FormControlNoBorder sx={{ width: '100%' }} variant="outlined">
					<StyledWrapper.CustomLabel
						error={hasError}
						value={defaultValue && !allEmpty}
						//Having required on customLabel will have double asterisks show up
						disabled={disabled ? true : false}
						htmlFor="outlined-adornment-password"
						name={`${name}-title`}
						shrink={isFormBuilder || undefined}
					>
						{isFormBuilder && getElementIcon(props.data?.key)}
						{parsedLabel?.[0] ? parsedLabel : props?.data?.label}
						{isFormBuilder && (
							<div>
								{props.data?.unique_identifier
									? `(ff-${props.data?.unique_identifier})`
									: `(tmp-${props.data?.temp_order})`}
							</div>
						)}
						{props?.data?.required && <StyledWrapper.AsteriskSpan> *</StyledWrapper.AsteriskSpan>}
					</StyledWrapper.CustomLabel>
					<StyledWrapper.OutlinedInputReadOnly
						id="outlined-adornment-password"
						type={'text'}
						disabled={disabled}
						style={{ width: '100%', color: 'red!important', marginTop: '16px' }}
						inputComponent={() => {
							return (
								<CustomInputLink
									hideLinkIcon={props?.data?.custom_options?.includes('hideIcon')}
									chip={props?.data?.chip}
									text={
										!props?.data?.custom_options?.includes('editOnlyLink')
											? defaultValue?.[0]
											: defaultValue[1]
									}
									link={defaultValue?.[1]}
								/>
							);
						}}
						value={defaultValue?.[0] || ''}
						label={parsedLabel?.[0] ? parsedLabel : props?.data?.label}
					/>
				</StyledWrapper.FormControlNoBorder>
			) : (
				<div style={{ display: 'flex', gap: '10px' }}>
					{!props?.data?.custom_options?.includes('editOnlyLink') && (
						<StyledWrapper.FormControlNoBorder sx={{ width: '100%' }} variant="outlined">
							<StyledWrapper.CustomLabel>
								<>
									{isFormBuilder && getElementIcon(props.data?.key)}
									{parsedLabel?.[0] ? parsedLabel : props?.data?.label}
									{isFormBuilder && (
										<div>
											{props.data?.unique_identifier
												? `(ff-${props.data?.unique_identifier})`
												: `(tmp-${props.data?.temp_order})`}
										</div>
									)}
									{props?.data?.required && (
										<StyledWrapper.AsteriskSpan> *</StyledWrapper.AsteriskSpan>
									)}
								</>
							</StyledWrapper.CustomLabel>
							<TextField
								error={hasError}
								id="outlined-error-helper-text"
								name={`${name}-title`}
								inputRef={ref}
								defaultValue={defaultValue?.[0] || ''}
								style={styleForNotFormBuilder}
								onChange={handleChange}
								required={props?.data?.required}
								multiline
								InputLabelProps={{ shrink: isFormBuilder || undefined }}
							/>
						</StyledWrapper.FormControlNoBorder>
					)}
					<StyledWrapper.FormControlNoBorder sx={{ width: '100%' }} variant="outlined">
						<StyledWrapper.CustomLabel>
							<>
								{isFormBuilder && getElementIcon(props.data?.key)}
								{`(External Link)`}
								{isFormBuilder && (
									<div>
										{props.data?.unique_identifier
											? `(ff-${props.data?.unique_identifier})`
											: `(tmp-${props.data?.temp_order})`}
									</div>
								)}
								{props?.data?.required && (
									<StyledWrapper.AsteriskSpan> *</StyledWrapper.AsteriskSpan>
								)}
							</>
						</StyledWrapper.CustomLabel>
						<TextField
							error={hasError}
							id="outlined-error-helper-text"
							name={`${name}-link`}
							inputRef={ref}
							data-testvalue={'test'}
							defaultValue={defaultValue?.[1] || ''}
							onChange={handleChange}
							style={styleForNotFormBuilder}
							required={props?.data?.required}
							multiline
							InputLabelProps={{ shrink: isFormBuilder || undefined }}
						/>
					</StyledWrapper.FormControlNoBorder>
				</div>
			)}
			<StyledWrapper.FormHelperTextContainer>
				<FormHelperText error={hasError}>
					{props.data.error?.error ? `${props.data.label} ${props.data.error?.error}` : ''}
				</FormHelperText>
				{parsedHelperText && <FormHelperText>{parsedHelperText}</FormHelperText>}
			</StyledWrapper.FormHelperTextContainer>
		</>
	);
});

MUILinkInput.displayName = 'MUI_LinkInput';
Registry.register('MUI_LinkInput', MUILinkInput);

const CustomInputLink = React.forwardRef((props, _ref) => {
	const { text, link, chip, hideLinkIcon } = props;
	const url = link ? `https://${removeHttpVariations(link)}` : '';
	return (
		<div style={{ display: 'flex', gap: '10px', alignItems: 'center', padding: '16.5px 14px' }}>
			{chip && (
				<Chip
					id={'programDetails-statusChip'}
					data-testid={`programDetails-statusChip`}
					label={chip}
					sx={{
						backgroundColor: getChipColor(chip),
						color: chip === 'Unassigned' ? 'black' : 'white!important',
						WebkitTextFillColor: chip === 'Unassigned' ? 'black' : 'white!important'
					}}
					size="small"
				/>
			)}
			<a
				onClick={() => {
					link?.[0] === '/' ? (window.location.href = link) : window.open(url);
				}}
			>
				{text}
			</a>

			{text && (
				<IconButton
					aria-label="toggle password visibility"
					onClick={() => {
						link?.[0] === '/' ? (window.location.href = link) : window.open(url);
					}}
					edge="end"
					style={{ padding: '0px' }}
				>
					{!hideLinkIcon && <LaunchIcon />}
				</IconButton>
			)}
		</div>
	);
});

CustomInputLink.displayName = 'CustomInputLink';

import { useState } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import StyledWrapper from '../IndividualProgram/style';
import { checkOverflow, getEntityPrefix } from '../../utils/utilFunctions';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import LinkOffIcon from '@mui/icons-material/LinkOff';

import Tooltip from '@mui/material/Tooltip';
import { GRID_REORDER_COL_DEF } from '@mui/x-data-grid-premium';
import IconButton from '@mui/material/IconButton';
import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest';
import InfoOutlineIcon from '../../assets/customIcons/infoOutlineIcon';
import { setFormMetaInfo } from '../../components/FormBuilderLibrary/slice';
import { usePostUnlinkTemplateMutation } from '../../services/endpoints/templatesEndpoints';
import { useDeleteFormMutation } from '../../services/endpoints/stagesEndpoints';
import TableTitle from '../../components/TableTitle/TableTitle';
import { getLocalAuth } from '../../utils/environmentUtils';

// openTooltip, setOpenTooltip
export const StageColumns = (columnWidths, handleAlert) => {
	const navigate = useNavigate();
	const [searchParams, setSearchParams] = useSearchParams();
	const [openTooltip, setOpenTooltip] = useState(0);
	const dispatch = useDispatch();
	const accountSource = useSelector((state) => state?.login?.accountSource);
	const apiToken = getLocalAuth(accountSource)?.access_token;

	const [unlinkTemplate] = usePostUnlinkTemplateMutation();
	const [deleteForm] = useDeleteFormMutation();
	const handleUnlinking = (parent, child, type) => {
		let unlinkTemplateOptions = {
			apiToken: apiToken,
			payload: {
				parent: parent,
				child: child,
				type: type
			}
		};
		unlinkTemplate(unlinkTemplateOptions)
			.unwrap()
			.then(async () => {
				handleAlert && handleAlert('Template Unlinked Successfully!', 'success');
			})
			.catch(() => {
				handleAlert && handleAlert('Template Unlinking Failed!', 'error');
			});
	};

	const handleDelete = (id) => {
		let deleteFormOptions = {
			id: id,
			apiToken: apiToken
		};

		deleteForm(deleteFormOptions)
			.unwrap()
			.then(async () => {
				handleAlert && handleAlert('Template Deleted Successfully!', 'success');
			})
			.catch(() => {
				handleAlert && handleAlert('Template Deletion Failed!', 'error');
			});
	};

	const columns = [
		{
			field: 'id',
			headerName: 'Entity',
			description: 'Entity',
			renderCell: (cellValues) => {
				const title = cellValues.row?.label || cellValues.row?.stage_name;
				const form_type = cellValues.row?.form_type?.toLowerCase();
				const stage_type = cellValues.row?.stage_type?.toLowerCase();
				const entityType = stage_type
					? stage_type === 'application'
						? 'Submission Stage'
						: 'Evaluation Stage'
					: form_type?.includes('application')
					? 'Application Form'
					: 'Review Form';
				const displayId =
					getEntityPrefix({ type: entityType, isBaseTemplate: true }) +
					cellValues.row?.unique_identifier;
				const titleData = {
					title,
					entityType,
					displayId,
					isTemplate: true,
					isArchived: cellValues.row?.is_archived
				};
				const handleCardClick = () => {
					const id = cellValues.row?.stage_id || cellValues.row?.form_id;
					dispatch(setFormMetaInfo({ id: title }));
					if (['Submission Stage', 'Evaluation Stage']?.includes(entityType)) {
						const baseURL = window.location.host;
						const type = entityType?.includes('Submission Stage') ? 'application' : 'evaluation';
						const page = entityType?.includes('Submission Stage')
							? 'submission-stages'
							: 'evaluation-stages';

						const path = `${baseURL}/templates/${page}?stageCreation=${type}&stageId=${id}`;
						window.location.href = `${window.location.protocol}//${path}`;
					} else if (['Application Form', 'Review Form']?.includes(entityType)) {
						const baseURL = window.location.host;
						const type = entityType?.includes('Application Form') ? 'application' : 'review';
						const page = entityType?.includes('Application Form')
							? 'base-application-forms'
							: 'base-review-forms';
						const path = `${baseURL}/templates/${page}?formBuilder=${type}&formId=${id}`;
						window.location.href = `${window.location.protocol}//${path}`;
					}
				};

				return <TableTitle titleData={titleData} handleCardClick={handleCardClick} />;
			},
			flex: !columnWidths.appId && 2,
			maxWidth: 400
		},
		{
			field: 'order',
			headerName: '#',
			headerAlign: 'center',
			align: 'center',
			renderCell: (cellValues) => {
				return cellValues?.row?.id;
			},
			width: 70,
			resizable: false
		},

		{
			field: 'form_type',
			headerName: 'Type',
			renderCell: (cellValues) => {
				const is_stage = cellValues?.row?.stage_type;
				const stage_type =
					cellValues?.row?.stage_type === 'application' ? 'Submission Stage' : 'Evaluation Stage';
				const form_type =
					cellValues?.row?.form_type === 'application' ? 'Application Form' : 'Review Form';

				const entityType = is_stage
					? stage_type === 'Submission Stage'
						? 'submission-stage'
						: 'evaluation-stage'
					: form_type?.includes('Application Form')
					? 'application-form'
					: 'review-form';

				const currentCell = {
					...cellValues,
					formattedValue: is_stage ? stage_type : form_type
				};
				return <BasicCellText cellValues={currentCell} />;
			},
			flex: !columnWidths.title && 1
		},

		{
			field: 'businessLogic',
			headerName: 'BL',
			renderCell: (cellValues) => {
				return cellValues?.formattedValue ? <SettingsSuggestIcon color="action" /> : '--';
			},
			width: 70,
			resizable: false
		},
		{
			field: 'instructions',
			headerName: 'Instructions',
			renderCell: (cellValues) => {
				return cellValues?.formattedValue && cellValues?.formattedValue?.length > 0 ? (
					<InfoOutlineIcon width={'20px'} height={'20px'} />
				) : (
					'--'
				);
			},
			width: 130,
			resizable: false
		},
		{
			field: 'action',
			headerName: 'Usage & Actions',
			renderCell: (cellValues) => {
				const isArchived = cellValues?.row?.is_archived;
				const isAdHoc = cellValues?.row?.is_adhoc;
				const isLinked = cellValues?.row?.linked_count > 0 && !isAdHoc;

				return (
					<div
						style={{
							display: 'flex',
							gap: '5px',
							alignItems: 'center'
						}}
					>
						{isAdHoc ? (
							<Tooltip
								arrow
								placement="top"
								title={
									!isArchived
										? 'Delete'
										: isLinked
										? 'Unlink all parents to enable.'
										: 'Unarchive parent to enable'
								}
							>
								<IconButton
									aria-label="delete"
									onClick={() => {
										!isArchived && !isLinked && handleDelete(cellValues?.row?.form_id);
									}}
								>
									<DeleteForeverIcon color={!isArchived && !isLinked ? 'error' : 'action'} />
								</IconButton>
							</Tooltip>
						) : (
							<Tooltip
								arrow
								placement="top"
								title={isArchived ? 'Unarchive parent to enable' : 'Detach'}
							>
								<IconButton aria-label="detach">
									<LinkOffIcon
										color={isArchived ? 'action' : 'error'}
										onClick={() =>
											!isArchived &&
											handleUnlinking(
												searchParams?.get('processId'),
												cellValues?.row?.stage_id,
												'stage'
											)
										}
									/>
								</IconButton>
							</Tooltip>
						)}
					</div>
				);
			},
			disableColumnMenu: true,
			disableReorder: true,
			sortable: false,
			resizable: false,
			width: 130,
			headerAlign: 'center',
			align: 'center'
		},
		{
			...GRID_REORDER_COL_DEF,
			width: 40
		}
	];

	return columns;
};

export const BasicCellText = ({ cellValues, openTooltip, setOpenTooltip, StartIcon, isLink }) => {
	return (
		<Tooltip
			open={openTooltip === `${cellValues?.field}-${cellValues?.id}`}
			onOpen={(e) => checkOverflow(e) && setOpenTooltip(`${cellValues?.field}-${cellValues?.id}`)}
			onClose={() => setOpenTooltip(0)}
			arrow
			placement="top"
			title={cellValues?.formattedValue}
		>
			<StyledWrapper.CellContainer
				data-testid={`cellContainer-${cellValues?.field}-${cellValues?.id}`}
			>
				{cellValues?.formattedValue && StartIcon && <StartIcon />}
				{!isLink ? (
					<StyledWrapper.CardRowInfo
						width={'100%'}
						data-testid={`rowInfo-${cellValues?.field}-${cellValues?.id}`}
					>
						{cellValues?.formattedValue ? cellValues?.formattedValue : '-'}
					</StyledWrapper.CardRowInfo>
				) : (
					<StyledWrapper.CardRowInfoLink
						width={'100%'}
						data-testid={`rowLink-${cellValues?.field}-${cellValues?.id}`}
					>
						{cellValues?.formattedValue ? cellValues?.formattedValue : '-'}
					</StyledWrapper.CardRowInfoLink>
				)}
			</StyledWrapper.CellContainer>
		</Tooltip>
	);
};

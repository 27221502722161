import { Dispatch, SetStateAction, MutableRefObject } from 'react';
import Radio from '@mui/material/Radio';
import { CopyProcessTableRow, TitleData } from '../types';
import TableTitle from '../../TableTitle/TableTitle';
import type { GridEnrichedColDef } from '@mui/x-data-grid/models/colDef/gridColDef';
import { GridRowId, GridValueFormatterParams } from '@mui/x-data-grid-premium';

interface GroupingColDefParams {
	handleRowSelection: Dispatch<
		SetStateAction<GridValueFormatterParams<CopyProcessTableRow> | undefined>
	>;
	stateRef: MutableRefObject<GridRowId | undefined>;
	handleTitleClick: (link: string) => void;
}

const tooltipText = 'Opens page in separate browser tab';

export const groupingColDef = ({
	handleRowSelection,
	stateRef,
	handleTitleClick
}: GroupingColDefParams): GridEnrichedColDef<CopyProcessTableRow> => {
	return {
		hideDescendantCount: true,
		field: 'title',
		headerName: 'Program',
		description: 'Program',
		valueFormatter: (cellValues) => {
			if (!cellValues?.id) {
				return null;
			}

			const row = cellValues.api.getRow(cellValues.id);

			const titleData = {
				status: row.status,
				entityType: 'Program',
				displayId: row.id,
				title: row.title
			} as TitleData;

			const link = `/programs/p-${row.id}/${
				row.app_process_is_ready_to_publish ? 'overview' : 'application-setup/application'
			}`;

			const handleCardClick = () => {
				handleTitleClick(link);
			};

			const title = (
				<TableTitle
					titleData={titleData}
					handleCardClick={handleCardClick}
					tooltipText={tooltipText}
					// Mutes ts errors
					handleExpand={undefined}
					style={undefined}
				/>
			);
			return (
				<div style={{ display: 'flex', justifyContent: 'center', gap: '5px' }}>
					<Radio
						checked={stateRef?.current === cellValues.id}
						value={cellValues.id}
						onChange={() => {
							stateRef.current = cellValues.id;
							handleRowSelection(cellValues);
						}}
						name="radio-buttons"
					/>
					{title}
				</div>
			);
		},
		flex: 1,
		resizable: true
	} as GridEnrichedColDef<CopyProcessTableRow>;
};

import { useEffect, useState, useRef } from 'react';

import StyledWrapper from '../../IndividualProgram/style';
import { checkOverflow } from '../../../utils/utilFunctions';
import { formatTimestamp } from '../../../utils/dateUtils';
import ApplicantProcess from '../../../assets/customIcons/applicantProcess';
import ReviewColorStageIcon from '../../../assets/customIcons/reviewColorStageIcon';
import { getChipColor, getChipTextColor } from '../../../utils/utilFunctions';

import { Tooltip, Chip } from '@mui/material';

export const ApplicationToReviewColumns = (columnWidths) => {
	const [openTooltip, setOpenTooltip] = useState(0);

	return [
		{
			field: 'submitter',
			headerName: 'Submitter',
			renderCell: (cellValues) => <SimpleTooltip cellValues={cellValues} />,
			flex: columnWidths.title || 1,
			resizable: true
		},
		{
			field: 'title',
			headerName: 'Application (Project Title)',
			renderCell: (cellValues) => <TitleTooltip cellValues={cellValues} />,
			flex: columnWidths.title || 1,
			resizable: true
		},
		{
			field: 'stageName',
			headerName: 'Evaluation Stage Name',
			renderCell: (cellValues) => <StageTooltip cellValues={cellValues} />,
			flex: !columnWidths.stageName && 1,
			resizable: true
		},
		{
			field: 'status',
			headerName: 'Stage Status',
			renderCell: (cellValues) => <StatusTooltip cellValues={cellValues} />,
			flex: !columnWidths.status && 1,
			maxWidth: 175
		},
		{
			field: 'totalReviewers',
			headerName: 'Number of Reviewers',
			renderCell: (cellValues) => (
				<BasicCellText
					openTooltip={openTooltip}
					setOpenTooltip={setOpenTooltip}
					cellValues={cellValues}
				/>
			),
			flex: columnWidths.reviewer || 1,
			maxWidth: 205,
			type: 'number',
			headerAlign: 'left',
			align: 'left',
			resizable: true
		},
		{
			field: 'reviewers',
			headerName: 'Assigned Reviewers',
			renderCell: (cellValues) => <SimpleTooltip cellValues={cellValues} />,
			flex: columnWidths.reviewFormName || 1,
			resizable: true
		}
	];
};

const SimpleTooltip = ({ cellValues }) => {
	const [isOverflowing, setIsOverflowing] = useState(false);
	const cardRowRef = useRef(null);
	useEffect(() => {
		if (cardRowRef.current) {
			setIsOverflowing(cardRowRef.current.scrollWidth > cardRowRef.current.clientWidth);
		}
	}, [cellValues]);

	return (
		<Tooltip arrow placement="top" title={isOverflowing ? `${cellValues?.formattedValue}` : ''}>
			<StyledWrapper.CardRowInfo
				ref={cardRowRef}
				data-testid={`${cellValues?.field}-${cellValues?.id}`}
			>
				{cellValues?.formattedValue}
			</StyledWrapper.CardRowInfo>
		</Tooltip>
	);
};

const StatusTooltip = ({ cellValues }) => {
	const [isOverflowing, setIsOverflowing] = useState(false);
	const cardRowRef = useRef(null);
	useEffect(() => {
		if (cardRowRef.current) {
			setIsOverflowing(cardRowRef.current.scrollWidth < 130);
		}
	}, [cellValues]);

	return (
		<Tooltip arrow placement="top" title={isOverflowing ? `${cellValues?.formattedValue}` : ''}>
			<Chip
				ref={cardRowRef}
				data-testid={`${cellValues?.field}-${cellValues?.id}`}
				label={cellValues?.formattedValue}
				sx={{
					backgroundColor: getChipColor(cellValues?.formattedValue),
					color: getChipTextColor(cellValues?.formattedValue)
				}}
				size="small"
			/>
		</Tooltip>
	);
};

const StageTooltip = ({ cellValues }) => {
	const [isOverflowing, setIsOverflowing] = useState(false);
	const cardRowRef = useRef(null);
	useEffect(() => {
		if (cardRowRef.current) {
			setIsOverflowing(cardRowRef.current.scrollWidth > cardRowRef.current.clientWidth);
		}
	}, [cellValues]);

	return (
		<Tooltip arrow placement="top" title={isOverflowing ? `${cellValues?.formattedValue}` : ''}>
			<StyledWrapper.CellContainer
				data-testid={`cellContainer-${cellValues?.field}-${cellValues?.id}`}
			>
				<ReviewColorStageIcon
					isActive={cellValues.row.is_current_stage}
					customColor={getChipColor(cellValues.row.status)}
				/>
				<StyledWrapper.CardRowInfo ref={cardRowRef}>
					{cellValues?.formattedValue}
				</StyledWrapper.CardRowInfo>
			</StyledWrapper.CellContainer>
		</Tooltip>
	);
};

const TitleTooltip = ({ cellValues }) => {
	const [isOverflowing, setIsOverflowing] = useState(false);
	const cardRowRef = useRef(null);
	useEffect(() => {
		if (cardRowRef.current) {
			setIsOverflowing(cardRowRef.current.scrollWidth > cardRowRef.current.clientWidth);
		}
	}, [cellValues]);

	return (
		<Tooltip arrow placement="top" title={isOverflowing ? `${cellValues?.formattedValue}` : ''}>
			<StyledWrapper.CellContainer
				data-testid={`cellContainer-${cellValues?.field}-${cellValues?.id}`}
			>
				<ApplicantProcess />
				<StyledWrapper.CardRowInfo ref={cardRowRef}>
					{cellValues?.formattedValue}
				</StyledWrapper.CardRowInfo>
			</StyledWrapper.CellContainer>
		</Tooltip>
	);
};

export const BasicCellText = ({
	cellValues,
	openTooltip,
	setOpenTooltip,
	StartIcon,
	isLink,
	isDate,
	isActive,
	emptyCell,
	isBlueprint,
	isDisabled,
	is_ID
}) => {
	const formattedText =
		isDate && cellValues?.formattedValue !== '--'
			? formatTimestamp(cellValues?.formattedValue)
			: cellValues?.formattedValue;

	return (
		<Tooltip
			open={openTooltip === `${cellValues?.field}-${cellValues?.id}`}
			onOpen={(e) => checkOverflow(e) && setOpenTooltip(`${cellValues?.field}-${cellValues?.id}`)}
			onClose={() => setOpenTooltip(0)}
			arrow
			placement="top"
			title={''}
		>
			<StyledWrapper.CellContainer
				data-testid={`cellContainer-${cellValues?.field}-${cellValues?.id}`}
			>
				{cellValues?.formattedValue && StartIcon && (
					<StartIcon isCurrent isActive={isActive} isBlueprint={isBlueprint} />
				)}
				{!isLink ? (
					<StyledWrapper.CardRowInfo
						width={'100%'}
						status={isDisabled}
						data-testid={`rowInfo-${cellValues?.field}-${cellValues?.id}`}
					>
						{cellValues?.formattedValue
							? is_ID
								? `[${formattedText}]`
								: formattedText
							: emptyCell
							? ''
							: ''}
					</StyledWrapper.CardRowInfo>
				) : (
					<StyledWrapper.CardRowInfoLink
						width={'100%'}
						status={isDisabled}
						data-testid={`rowLink-${cellValues?.field}-${cellValues?.id}`}
					>
						{cellValues?.formattedValue
							? is_ID
								? `[${formattedText}]`
								: formattedText
							: emptyCell
							? ''
							: ''}
					</StyledWrapper.CardRowInfoLink>
				)}
			</StyledWrapper.CellContainer>
		</Tooltip>
	);
};

import { useMemo, useRef, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import Tooltip from '@mui/material/Tooltip';
import Chip from '@mui/material/Chip';

import StyledWrapper from '../IndividualProgram/style';

import {
	getChipColor,
	getChipTextColor,
	appStagesCompletedAndAfter
} from '../../utils/utilFunctions';
import ProgressBar from '../../components/Graphs/ProgressBar/ProgressBar';
import { formatTimestamp } from '../../utils/dateUtils';
import TableTitle from '../../components/TableTitle/TableTitle';
import ReviewStageIcon from '../../assets/customIcons/reviewStageIcon';
import { getEntityPrefix } from '../../utils/utilFunctions';
const ReviewColumns = () => {
	const navigate = useNavigate();
	const [searchParams] = useSearchParams();
	const columnWidths = useMemo(() => {
		return {};
	}, []);

	const columns = [
		{
			field: 'org_name',
			headerName: 'Submitter',
			description: 'Submitter',
			renderCell: (cellValues) => {
				cellValues.formattedValue = cellValues?.formattedValue || '--';
				return <BasicCellText cellValues={cellValues} />;
			},
			flex: !columnWidths.title && 1
		},
		{
			field: 'project_title',
			headerName: 'Application (Project Title)',
			description: 'Application (Project Title)',
			renderCell: (cellValues) => {
				const id = cellValues.row?.unique_identifier;
				const formattedID = id ? `api-${id}` : '--';
				const currentStage = cellValues.row?.form_stage_id;
				const programId = cellValues.row?.program?.programId;
				const titleData = {
					displayId: formattedID,
					title: cellValues.row.project_title,
					status: cellValues.row.status,
					entityType: 'Application Instance'
				};
				const handleCardClick = () => {
					//ARM-116. Need to keep iframe as a query param
					navigate(
						`/programs/p-${programId}/applications/${formattedID}?formtype=review${
							searchParams?.get('iFrame') === 'true' ? '&iFrame=true' : ''
						}`
					);
				};
				return (
					<>
						<TableTitle handleCardClick={handleCardClick} titleData={titleData} />
					</>
				);
			},
			flex: !columnWidths.appId && 1,
			minWidth: 250
		},
		{
			field: 'status',
			headerName: 'Application Status',
			description: 'Application Status',
			renderCell: (cellValues) => {
				return (
					<Chip
						data-testid={`${cellValues?.field}-${cellValues?.id}`}
						label={cellValues?.formattedValue}
						sx={{
							backgroundColor: getChipColor(cellValues?.formattedValue),
							color: getChipTextColor(cellValues?.formattedValue)
						}}
						size="small"
					/>
				);
			},
			flex: !columnWidths.status && 1
		},
		{
			// This will render the first stage, not always the active stage. ARM-342
			field: 'stage',
			headerName: 'Active Stage',
			description: 'Active Stage',
			renderCell: (cellValues) => {
				const currentStage = cellValues?.row?.form_stage_id;
				const applicationStatus = cellValues?.row?.status;
				cellValues.formattedValue = currentStage
					? `${currentStage?.name} [${getEntityPrefix({
							type:
								currentStage?.stage_type === 'application'
									? 'submission-stage'
									: 'evaluation-stage',
							isInstance: true
					  })}${currentStage?.unique_identifier}]`
					: '--';
				return (
					!appStagesCompletedAndAfter.includes(applicationStatus) && (
						<BasicCellText
							cellValues={cellValues}
							StartIcon={ReviewStageIcon}
							IconStatus={currentStage?.status}
						/>
					)
				);
			},
			flex: !columnWidths.stage && 1,
			minWidth: 260
		},

		{
			field: 'stage_status',
			headerName: 'Stage Status',
			description: 'Stage Status',
			renderCell: (cellValues) => {
				const currentStage = cellValues?.row?.form_stage_id;
				const applicationStatus = cellValues?.row?.status;
				cellValues.formattedValue = currentStage?.status;
				return (
					<>
						{currentStage && !appStagesCompletedAndAfter.includes(applicationStatus) && (
							<Chip
								data-testid={`${cellValues?.field}-${cellValues?.id}`}
								label={cellValues?.formattedValue}
								sx={{
									backgroundColor: getChipColor(cellValues?.formattedValue),
									color: getChipTextColor(cellValues?.formattedValue)
								}}
								size="small"
							/>
						)}
					</>
				);
			},
			flex: !columnWidths.status && 1
		},
		// ARM-215 Hiding this
		// {
		// 	field: 'created_on',
		// 	type: 'date',
		// 	headerName: 'Started',
		// 	description: 'Started',
		// 	renderCell: (cellValues) => {
		// 		const currentStage = cellValues?.row?.form_stage_id;
		// 		const applicationStatus = cellValues?.row?.status;
		// 		cellValues.formattedValue = currentStage?.start_date;
		// 		return currentStage && !appStagesCompletedAndAfter.includes(applicationStatus) ? (
		// 			<BasicCellText isDate cellValues={cellValues} />
		// 		) : (
		// 			''
		// 		);
		// 	},
		// 	flex: !columnWidths.startDate && 1,
		// },
		{
			field: 'last_updated_on',
			type: 'date',
			headerName: 'Last Edited',
			description: 'Last Edited',
			renderCell: (cellValues) => {
				const currentStage = cellValues?.row?.form_stage_id;
				const applicationStatus = cellValues?.row?.status;
				return currentStage && !appStagesCompletedAndAfter.includes(applicationStatus) ? (
					<BasicCellText isDate cellValues={cellValues} />
				) : (
					''
				);
			},
			flex: !columnWidths.endDate && 1
		}
		// {
		// ARM-215 Hiding this
		// field: 'remainingTime',
		// headerName: 'Remaining Time',
		// renderCell: (cellValues) => {
		// 	const row = cellValues?.row;
		// 	const currentStage = row?.form_stage_id;
		// 	const applicationStatus = cellValues?.row?.status;
		// 	const start_date = currentStage?.start_date || row?.process_start_date;
		// 	const end_date = currentStage?.end_date || row?.process_end_date;
		// 	const startDate = start_date?.split('T')?.[0] + 'T00:00:00';
		// 	const endDate = end_date?.split('T')?.[0] + 'T00:00:00';
		// 	let remainingTimeData = {
		// 		startDate: new Date(startDate),
		// 		endDate: new Date(endDate),
		// 		isCountdown: true
		// 	};
		// 	return (
		// 		currentStage &&
		// 		!appStagesCompletedAndAfter.includes(applicationStatus) && (
		// 			<ProgressBar testTitle={'manageApplication'} data={remainingTimeData} />
		// 		)
		// 	);
		// },
		// flex: !columnWidths.remainingTime && 1
		// }
	];

	return columns;
};

export default ReviewColumns;

export const BasicCellText = ({ cellValues, StartIcon, IconStatus, isDate }) => {
	const formattedText = isDate
		? formatTimestamp(cellValues?.formattedValue)
		: cellValues?.formattedValue;

	//This is overflow will only add the tooltip on initial render. Not if the user adjusts the column sizing
	const [isOverflowing, setIsOverflowing] = useState(false);
	const cardRowInfoRef = useRef(null);

	useEffect(() => {
		if (cardRowInfoRef.current) {
			setIsOverflowing(cardRowInfoRef.current.scrollWidth > cardRowInfoRef.current.clientWidth);
		}
	}, [formattedText]);

	return (
		<StyledWrapper.CellContainer
			data-testid={`cellContainer-${cellValues?.field}-${cellValues?.id}`}
		>
			{cellValues?.formattedValue && StartIcon && (
				<StartIcon width={'20px'} status={IconStatus} isCurrentStage={true} />
			)}
			<Tooltip
				arrow
				placement="top"
				title={isOverflowing && cellValues?.formattedValue ? formattedText : ''}
			>
				<StyledWrapper.CardRowInfo
					ref={cardRowInfoRef}
					width={'100%'}
					data-testid={`rowInfo-${cellValues?.field}-${cellValues?.id}`}
				>
					{cellValues?.formattedValue ? formattedText : ''}
				</StyledWrapper.CardRowInfo>
			</Tooltip>
		</StyledWrapper.CellContainer>
	);
};

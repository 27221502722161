import { getIconColor, getOldBlueColor } from '../../utils/utilFunctions';

const ReviewStageIcon = ({
	isActive,
	width,
	isBlueprint,
	isCurrentStage,
	isTemplate,
	isWarning,
	isArchived,
	customColor,
	status
}) => {
	const color = getIconColor(status);
	const templateFillColor = isArchived
		? 'rgba(0,0,0,0.38)'
		: isActive
		? customColor || getOldBlueColor()
		: isWarning
		? '#ED6C02'
		: 'rgba(0, 0, 0, 0.54)';

	return isTemplate ? (
		<svg
			width={width || '30'}
			height={width || '30'}
			viewBox="0 0 24 25"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M11.9983 22.9947C17.7597 22.9947 22.4302 18.3242 22.4302 12.5628C22.4302 6.80139 17.7597 2.13086 11.9983 2.13086C6.23693 2.13086 1.56641 6.80139 1.56641 12.5628C1.56641 18.3242 6.23693 22.9947 11.9983 22.9947ZM11.068 6.88986C10.7698 6.59172 10.3697 6.42696 9.95388 6.42696H5.89768C5.03466 6.42696 4.3364 7.13307 4.3364 7.99609L4.32855 17.4109C4.32855 18.2739 5.03466 18.98 5.89768 18.98H18.4507C19.3137 18.98 20.0198 18.2739 20.0198 17.4109V9.56522C20.0198 8.7022 19.3137 7.99609 18.4507 7.99609H12.1742L11.068 6.88986ZM12.1742 9.56522C9.67785 9.56522 7.54598 11.1919 6.68224 13.488C7.54598 15.7842 9.67785 17.4109 12.1742 17.4109C14.6705 17.4109 16.8024 15.7842 17.6661 13.488C16.8024 11.1919 14.6705 9.56522 12.1742 9.56522ZM12.1742 16.1033C10.7962 16.1033 9.67785 14.9316 9.67785 13.488C9.67785 12.0444 10.7962 10.8728 12.1742 10.8728C13.5522 10.8728 14.6705 12.0444 14.6705 13.488C14.6705 14.9316 13.5522 16.1033 12.1742 16.1033ZM12.1742 11.9189C11.3454 11.9189 10.6764 12.6198 10.6764 13.488C10.6764 14.3563 11.3454 15.0572 12.1742 15.0572C13.003 15.0572 13.672 14.3563 13.672 13.488C13.672 12.6198 13.003 11.9189 12.1742 11.9189Z"
				fill={templateFillColor}
			/>
			<path
				d="M0.317037 2.12839C0.077019 1.36669 0.782692 0.644831 1.54964 0.867514L3.95423 1.56569C4.94153 1.85235 4.9056 3.26327 3.90499 3.49931L3.77741 3.52941C3.40068 3.61828 3.10907 3.91658 3.02876 4.29523L3.00157 4.42345C2.78828 5.42915 1.37853 5.49707 1.06956 4.51653L0.317037 2.12839Z"
				fill={templateFillColor}
			/>
			<path
				d="M0.430318 23.0171C0.1903 23.7788 0.895974 24.5007 1.66292 24.278L4.06751 23.5798C5.05481 23.2932 5.01888 21.8822 4.01828 21.6462L3.89069 21.6161C3.51396 21.5272 3.22235 21.2289 3.14204 20.8503L3.11485 20.7221C2.90156 19.7164 1.49182 19.6484 1.18284 20.629L0.430318 23.0171Z"
				fill={templateFillColor}
			/>
			<path
				d="M23.6869 2.10789C23.9269 1.34619 23.2212 0.624323 22.4543 0.847006L20.0497 1.54518C19.0624 1.83184 19.0983 3.24276 20.0989 3.4788L20.2265 3.5089C20.6032 3.59777 20.8948 3.89607 20.9751 4.27472L21.0023 4.40294C21.2156 5.40864 22.6254 5.47656 22.9343 4.49602L23.6869 2.10789Z"
				fill={templateFillColor}
			/>
			<path
				d="M23.6869 23.0171C23.9269 23.7788 23.2212 24.5007 22.4543 24.278L20.0497 23.5798C19.0624 23.2932 19.0983 21.8822 20.0989 21.6462L20.2265 21.6161C20.6032 21.5272 20.8948 21.2289 20.9751 20.8503L21.0023 20.7221C21.2156 19.7164 22.6254 19.6484 22.9343 20.629L23.6869 23.0171Z"
				fill={templateFillColor}
			/>
		</svg>
	) : isBlueprint ? (
		<svg
			width={width || '30'}
			height={width || '30'}
			viewBox="0 0 24 25"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M12.0003 22.7745C17.7617 22.7745 22.4322 18.1039 22.4322 12.3426C22.4322 6.58117 17.7617 1.91064 12.0003 1.91064C6.23889 1.91064 1.56836 6.58117 1.56836 12.3426C1.56836 18.1039 6.23889 22.7745 12.0003 22.7745ZM11.0699 6.66964C10.7718 6.37151 10.3716 6.20675 9.95583 6.20675H5.89963C5.03661 6.20675 4.33835 6.91286 4.33835 7.77588L4.33051 17.1906C4.33051 18.0537 5.03661 18.7598 5.89963 18.7598H18.4527C19.3157 18.7598 20.0218 18.0537 20.0218 17.1906V9.34501C20.0218 8.48199 19.3157 7.77588 18.4527 7.77588H12.1761L11.0699 6.66964ZM12.1761 9.34501C9.67981 9.34501 7.54793 10.9717 6.6842 13.2678C7.54793 15.564 9.67981 17.1906 12.1761 17.1906C14.6725 17.1906 16.8044 15.564 17.6681 13.2678C16.8044 10.9717 14.6725 9.34501 12.1761 9.34501ZM12.1761 15.883C10.7982 15.883 9.67981 14.7114 9.67981 13.2678C9.67981 11.8242 10.7982 10.6526 12.1761 10.6526C13.5541 10.6526 14.6725 11.8242 14.6725 13.2678C14.6725 14.7114 13.5541 15.883 12.1761 15.883ZM12.1761 11.6987C11.3474 11.6987 10.6783 12.3996 10.6783 13.2678C10.6783 14.1361 11.3474 14.837 12.1761 14.837C13.0049 14.837 13.674 14.1361 13.674 13.2678C13.674 12.3996 13.0049 11.6987 12.1761 11.6987Z"
				fill={isActive ? customColor || getOldBlueColor() : 'rgba(0, 0, 0, 0.54)'}
			/>
		</svg>
	) : isCurrentStage ? (
		<svg
			width={width || '30'}
			height={width || '30'}
			viewBox="0 0 25 23"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M7.67 6C8.2 6 8.71 6.21 9.09 6.59L10.5 8H18.5C19.6 8 20.5 8.9 20.5 10V20C20.5 21.1 19.6 22 18.5 22H2.5C1.4 22 0.5 21.1 0.5 20L0.51 8C0.51 6.9 1.4 6 2.5 6H7.67ZM3.5 15C4.60091 12.0733 7.31818 10 10.5 10C13.6818 10 16.3991 12.0733 17.5 15C16.3991 17.9267 13.6818 20 10.5 20C7.31818 20 4.60091 17.9267 3.5 15ZM7.31818 15C7.31818 16.84 8.74364 18.3333 10.5 18.3333C12.2564 18.3333 13.6818 16.84 13.6818 15C13.6818 13.16 12.2564 11.6667 10.5 11.6667C8.74364 11.6667 7.31818 13.16 7.31818 15ZM8.59091 15C8.59091 13.8933 9.44364 13 10.5 13C11.5564 13 12.4091 13.8933 12.4091 15C12.4091 16.1067 11.5564 17 10.5 17C9.44364 17 8.59091 16.1067 8.59091 15Z"
				fill={isActive ? customColor || getOldBlueColor() : color || 'rgba(0, 0, 0, 0.54)'}
			/>
			<circle cx="18.5" cy="6" r="6" fill="white" />
			<path
				d="M18.4992 8.35503L20.5742 9.71461C20.9542 9.96377 21.4192 9.59544 21.3192 9.12961L20.7692 6.57294L22.6042 4.85044C22.9392 4.53628 22.7592 3.94044 22.3192 3.90253L19.9042 3.68044L18.9592 1.26461C18.7892 0.825859 18.2092 0.825859 18.0392 1.26461L17.0942 3.67503L14.6792 3.89711C14.2392 3.93503 14.0592 4.53086 14.3942 4.84503L16.2292 6.56753L15.6792 9.12419C15.5792 9.59002 16.0442 9.95836 16.4242 9.70919L18.4992 8.35503Z"
				fill={customColor ? '#F57F17' : '#F9A825'}
			/>
		</svg>
	) : (
		<svg
			width={width || '30'}
			height={width || '30'}
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M9.17 4C9.7 4 10.21 4.21 10.59 4.59L12 6H20C21.1 6 22 6.9 22 8V18C22 19.1 21.1 20 20 20H4C2.9 20 2 19.1 2 18L2.01 6C2.01 4.9 2.9 4 4 4H9.17ZM5 13C6.10091 10.0733 8.81818 8 12 8C15.1818 8 17.8991 10.0733 19 13C17.8991 15.9267 15.1818 18 12 18C8.81818 18 6.10091 15.9267 5 13ZM8.81818 13C8.81818 14.84 10.2436 16.3333 12 16.3333C13.7564 16.3333 15.1818 14.84 15.1818 13C15.1818 11.16 13.7564 9.66667 12 9.66667C10.2436 9.66667 8.81818 11.16 8.81818 13ZM10.0909 13C10.0909 11.8933 10.9436 11 12 11C13.0564 11 13.9091 11.8933 13.9091 13C13.9091 14.1067 13.0564 15 12 15C10.9436 15 10.0909 14.1067 10.0909 13Z"
				fill={isActive ? customColor || getOldBlueColor() : color}
			/>
		</svg>
	);
};

export default ReviewStageIcon;

import { useState, useEffect, useRef } from 'react';

import { TableIcon } from '../../../components/TableIcon/TableIcon';
import StyledWrapper from '../style';

import { Tooltip } from '@mui/material';

const TitleWithIdCell = ({ titleData, customColor, isCurrentStage }) => {
	const cardRowTitleRef = useRef(null);
	const [isOverflowing, setIsOverflowing] = useState(false);
	const { displayId, title, entityType } = titleData;

	useEffect(() => {
		if (cardRowTitleRef.current) {
			setIsOverflowing(cardRowTitleRef.current.scrollWidth > cardRowTitleRef.current.clientWidth);
		}
	}, [titleData]);

	return (
		<StyledWrapper.TitleAndIdCardRowContainer width={'100%'}>
			<div style={{ display: 'contents' }}>
				<div style={{ display: 'flex' }}>
					<TableIcon
						entityType={entityType}
						customColor={customColor}
						isActive={true}
						isCurrentStage={isCurrentStage}
					/>
				</div>
				<Tooltip title={isOverflowing ? `${title}` : ''} arrow placement="top">
					<StyledWrapper.TitleAndIdCardRowTitle ref={cardRowTitleRef} display="inline">
						{title}
					</StyledWrapper.TitleAndIdCardRowTitle>
				</Tooltip>

				<StyledWrapper.TitleAndIdCardRowId>[{displayId}]</StyledWrapper.TitleAndIdCardRowId>
			</div>
		</StyledWrapper.TitleAndIdCardRowContainer>
	);
};

export default TitleWithIdCell;

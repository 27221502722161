import { getOldBlueColor } from '../../utils/utilFunctions';

const PersonIcon = ({ isActive, width, isDelete }) => {
	return isDelete ? (
		<svg
			width={width || '30'}
			height={width || '30'}
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M12.2889 1C14.6596 1 16.5993 2.93967 16.5993 5.31039C16.5993 7.6811 14.6596 9.62077 12.2889 9.62077C9.91817 9.62077 7.97849 7.6811 7.97849 5.31039C7.97849 2.93967 9.91817 1 12.2889 1ZM3 14.9872C5.56468 12.9829 8.77591 11.776 12.2889 11.776C14.0314 11.776 15.6997 12.0729 17.2516 12.6168C14.2317 13.3933 12 16.1344 12 19.3967C12 19.7355 12.0241 20.0686 12.0706 20.3945C8.17612 20.316 4.81955 18.1397 3 14.9872Z"
				fill="#D32F2F"
			/>
			<path
				d="M15.4286 21.5076C15.4286 21.9965 15.8143 22.3965 16.2857 22.3965H19.7143C20.1857 22.3965 20.5714 21.9965 20.5714 21.5076V16.1743H15.4286V21.5076ZM16.7871 18.6587C16.62 18.4854 16.62 18.2054 16.7871 18.032C16.9543 17.8587 17.2243 17.8587 17.3914 18.032L18 18.6587L18.6043 18.032C18.7714 17.8587 19.0414 17.8587 19.2086 18.032C19.3757 18.2054 19.3757 18.4854 19.2086 18.6587L18.6043 19.2854L19.2086 19.912C19.3757 20.0854 19.3757 20.3654 19.2086 20.5387C19.0414 20.712 18.7714 20.712 18.6043 20.5387L18 19.912L17.3957 20.5387C17.2286 20.712 16.9586 20.712 16.7914 20.5387C16.6243 20.3654 16.6243 20.0854 16.7914 19.912L17.3957 19.2854L16.7871 18.6587ZM20.5714 14.8409H19.5L19.1957 14.5254C19.1186 14.4454 19.0071 14.3965 18.8957 14.3965H17.1043C16.9929 14.3965 16.8814 14.4454 16.8043 14.5254L16.5 14.8409H15.4286C15.1929 14.8409 15 15.0409 15 15.2854C15 15.5298 15.1929 15.7298 15.4286 15.7298H20.5714C20.8071 15.7298 21 15.5298 21 15.2854C21 15.0409 20.8071 14.8409 20.5714 14.8409Z"
				fill="#D32F2F"
			/>
		</svg>
	) : (
		<svg
			width={width || '30'}
			height={width || '30'}
			viewBox="0 0 20 21"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M9.99967 10.6862C11.8413 10.6862 13.333 9.19453 13.333 7.35286C13.333 5.5112 11.8413 4.01953 9.99967 4.01953C8.15801 4.01953 6.66634 5.5112 6.66634 7.35286C6.66634 9.19453 8.15801 10.6862 9.99967 10.6862ZM9.99967 12.3529C7.77467 12.3529 3.33301 13.4695 3.33301 15.6862V17.3529H16.6663V15.6862C16.6663 13.4695 12.2247 12.3529 9.99967 12.3529Z"
				fill={isActive ? getOldBlueColor() : 'rgba(0, 0, 0, 0.54)'}
			/>
		</svg>
	);
};

export default PersonIcon;

export const budgetFormSchema = [
	{
		id: 'CC8BE050-8776-4E75-AB91-3962A21AA260',
		element: 'CustomElement',
		text: 'Single Line Input',
		group_name: 'Controls',
		required: false,
		key: 'MUI_Input',
		custom: true,
		forward_ref: true,
		custom_options: ['hideLabel'],
		can_have_page_break_before: true,
		can_have_alternate_form: true,
		can_have_display_horizontal: true,
		can_have_option_correct: true,
		can_have_option_value: true,
		can_populate_from_api: true,
		field_name: 'mui_input_909B07FE-2254-4ABD-B7DB-B107215ECF00',
		label: '<p>Budget Template</p>\n',
		parent_index: 0,
		dirty: false
	}
];

import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import ArrowCircleDownIcon from '@mui/icons-material/ArrowCircleDown';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import ImageIcon from '@mui/icons-material/Image';
import SettingsIcon from '@mui/icons-material/Settings';
import Download from '@mui/icons-material/Download';

export const FormElementIcon = ({ element }) => {
	switch (element) {
		case 'Single Line Input':
			return <ExitToAppIcon />;
		case 'Paragraph Input':
			return <ExitToAppIcon />;
		case 'Dropdown':
			return <ArrowCircleDownIcon />;
		case 'Checkboxes':
			return <CheckBoxIcon />;
		case 'Radio Button':
			return <RadioButtonCheckedIcon />;
		case 'Image':
			return <ImageIcon />;
		case 'File Attachment (Applicant Download)':
			return <Download />;
		default:
			return <SettingsIcon />;
	}
};

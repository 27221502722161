import React, { useState, useEffect, useMemo } from 'react';

import { Registry } from '../../FormBuilderLibrary';
import { stringToHTML, useFileUtils, getElementIcon } from '../utils';
import StyledWrapper from './style';

import { FormHelperText, IconButton } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import UploadIcon from '@mui/icons-material/Upload';
import DownloadIcon from '@mui/icons-material/Download';
import CloseIcon from '@mui/icons-material/Close';

export const MUIFileUpload = React.forwardRef((props, _ref) => {
	let { defaultValue, disabled, isFormBuilder, name } = props;
	const { sendFileToServer, downloadFile } = useFileUtils();
	const [loading, setLoading] = useState(false);
	const [selectedFile, setSelectedFile] = useState(null);
	const [filePath, setFilePath] = useState(defaultValue);
	const [fileName, setFileName] = useState(defaultValue?.slice(37) || '');
	const [hasError, setHasError] = useState(props?.data?.error || false);

	const handleFileChange = (event) => {
		setSelectedFile(event.target.files[0]);
		setFileName(event.target.files[0].name);
		setHasError(false);
	};

	const handleFileDownload = () => {
		downloadFile(filePath);
	};

	const handleDelete = () => {
		setFilePath('');
	};

	useEffect(() => {
		setHasError(props?.data?.error || false);
	}, [props?.data?.error]);
	useEffect(() => {
		// JavaScript: Attach an event listener to the input button
		if (selectedFile) {
			setLoading(true);
			const reader = new FileReader();

			reader.onload = (loadEvent) => {
				const fileData = loadEvent.target.result;

				// Now you can send the file data to the server using an API call (e.g., fetch)
				sendFileToServer(selectedFile.name, fileData)
					.then((data) => {
						setFilePath(`${data.uuid}-${data.filename}`);
						setLoading(false);
					})
					.catch((error) => {
						console.error('Error:', error);
					});
			};
			reader.readAsArrayBuffer(selectedFile);
		}
	}, [selectedFile]);

	useEffect(() => {
		if (filePath) {
			const triggerBlur = document.getElementById('hiddenBlurTriggerInput');
			triggerBlur?.click();
		}
	}, [filePath]);

	const parsedLabel = useMemo(() => stringToHTML(props?.data?.label), [props?.data?.label]);
	const parsedHelperText = useMemo(
		() => stringToHTML(props?.data?.help_message),
		[props?.data?.help_message]
	);

	return (
		<>
			<div className="inner-form-group addBorder" name={name}>
				<StyledWrapper.CustomLabel
					value={defaultValue?.length > 0}
					disabled={disabled ? true : false}
					required={props?.data?.required}
					htmlFor="outlined-adornment-password"
					shrink={true}
					className={'customLabel minus6MarginTop'}
				>
					{isFormBuilder && getElementIcon(props.data?.key)}
					{parsedLabel?.[0] ? parsedLabel : props?.data?.label}
					{isFormBuilder && (
						<div>
							{props.data?.unique_identifier
								? `(ff-${props.data?.unique_identifier})`
								: `(tmp-${props.data?.temp_order})`}
						</div>
					)}
				</StyledWrapper.CustomLabel>
				<StyledWrapper.BorderOutline
					error={hasError}
					className={'customInputOutline'}
					disabled={disabled ? true : false}
				>
					{!filePath ? (
						<LoadingButton
							className="noMarginTop"
							disabled={disabled}
							loading={loading}
							variant="contained"
							startIcon={<UploadIcon />}
							component="label"
							sx={{
								fontWeight: '500!important',
								lineHeight: '1.75!important',
								fontSize: '13px!important',
								letterSpacing: '0.02857rem!important'
							}}
						>
							{'Add File'}
							<input type="file" hidden onChange={handleFileChange} />
						</LoadingButton>
					) : (
						<div style={{ display: 'flex', gap: '10px' }}>
							<LoadingButton
								id={`${props?.data?.field_name}-download`}
								onClick={handleFileDownload}
								loading={loading}
								value={filePath}
								startIcon={<DownloadIcon />}
							>
								{fileName}
							</LoadingButton>
							{!disabled && (
								<IconButton onClick={handleDelete} aria-label="delete" color={'error'}>
									<CloseIcon />
								</IconButton>
							)}
						</div>
					)}
				</StyledWrapper.BorderOutline>
			</div>
			<StyledWrapper.FormHelperTextContainer>
				<FormHelperText error={hasError}>
					{' '}
					{props.data.error?.error ? `${props.data.label} ${props.data.error?.error}` : ''}
				</FormHelperText>
				{parsedHelperText && <FormHelperText>{parsedHelperText}</FormHelperText>}
			</StyledWrapper.FormHelperTextContainer>
		</>
	);
});

MUIFileUpload.displayName = 'MUI_FileUpload';
Registry.register('MUI_FileUpload', MUIFileUpload);

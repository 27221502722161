import React, { useState, useEffect, useMemo } from 'react';
import { Registry } from '../../FormBuilderLibrary';
import { getElementIcon, stringToHTML } from '../utils';

import StyledWrapper from './style';

import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import FormHelperText from '@mui/material/FormHelperText';

export const MUIDropdown = React.forwardRef((props, ref) => {
	const { name, defaultValue, disabled, isFormBuilder } = props;
	const [actualValue, setActualValue] = useState(
		props?.data?.options?.find((e) => e?.key === defaultValue)
	);
	const [hasError, setHasError] = useState(props?.data?.error || false);

	const parsedLabel = useMemo(() => stringToHTML(props?.data?.label), [props?.data?.label]);
	const parsedHelperText = useMemo(
		() => stringToHTML(props?.data?.help_message),
		[props?.data?.help_message]
	);
	useEffect(() => {
		setHasError(props?.data?.error || false);
	}, [props?.data?.error]);
	const handleChange = (selectedOption) => {
		setActualValue(selectedOption);
		setHasError(false);
	};
	const styleForNotFormBuilder = !isFormBuilder
		? { width: '100%', marginTop: '16px' }
		: { width: '100%' };
	return (
		<>
			<FormControl fullWidth>
				<StyledWrapper.CustomLabel
					value={parsedLabel?.[0] ? parsedLabel : props?.data?.label}
					disabled={disabled ? true : false}
					required={props?.data?.required}
				>
					{isFormBuilder && getElementIcon(props.data?.key)}
					{props?.data?.label}
					{isFormBuilder && (
						<div>
							{props.data?.unique_identifier
								? `(ff-${props.data?.unique_identifier})`
								: `(tmp-${props.data?.temp_order})`}
						</div>
					)}
				</StyledWrapper.CustomLabel>
				<Select
					sx={styleForNotFormBuilder}
					error={hasError}
					inputProps={{ ref: ref, actualValue: actualValue?.value }}
					id={`DropdownID_${props?.field_name}`}
					disabled={disabled}
					defaultValue={defaultValue}
					onChange={(x) => {
						handleChange(props?.data?.options?.find((e) => e?.key === x?.target?.value));
					}}
					name={name}
				>
					{props?.data?.options?.map((e) => {
						return (
							<MenuItem key={e?.key} value={e?.key}>
								{e?.text}
							</MenuItem>
						);
					})}
				</Select>
			</FormControl>
			<StyledWrapper.FormHelperTextContainer>
				<FormHelperText error={hasError}>
					{' '}
					{props.data.error?.error ? `${props.data.label} ${props.data.error?.error}` : ''}
				</FormHelperText>
				{parsedHelperText && <FormHelperText>{parsedHelperText}</FormHelperText>}
			</StyledWrapper.FormHelperTextContainer>
		</>
	);
});

MUIDropdown.displayName = 'MUI_Dropdown';
Registry.register('MUI_Dropdown', MUIDropdown);

import { useState } from 'react';

import { Tooltip } from '@mui/material';
import { IconButton } from '@mui/material';

import SaveDialog from '../SaveDialog/SaveDialog';
import { useAssignReviewMutation } from '../../services/endpoints/reviewEndpoints';
import { getLocalAuth } from '../../utils/environmentUtils';
import { useSelector } from 'react-redux';

const AssignReviewButton = ({ onClick, isBulk, reviewerID, stageID }) => {
	const [assignReviewer] = useAssignReviewMutation();
	const accountSource = useSelector((state) => state?.login?.accountSource);
	const apiToken = getLocalAuth(accountSource)?.access_token;
	const [saveModal, setSaveModal] = useState(false);
	const handleAssignReviewer = () => {
		const options = {
			uniqueID: stageID?.replace('esi-', ''),
			reviewerID: reviewerID,
			apiToken: apiToken
		};
		assignReviewer(options)
			.unwrap()
			.then(() => {
				setSaveModal({ ...saveModal, isLoading: false, isSuccess: true });
			})
			.catch(() => setSaveModal({ ...saveModal, isLoading: false, isError: true }));
	};

	return (
		<>
			<Tooltip title="Add to assigning list" placement="top">
				<IconButton
					onClick={(e) => (isBulk ? onClick(e) : setSaveModal({ isOpen: true }))}
					variant="outlined"
					style={{ display: 'flex', borderRadius: 28, MaxWidth: 20 }}
					aria-label="fingerprint"
				>
					<span>
						<img src={'/images/add.svg'} />
					</span>
				</IconButton>
			</Tooltip>

			{saveModal && (
				<SaveDialog
					isOpen={saveModal?.isOpen}
					isYesNo
					title={'Assign Reviewer'}
					loading={saveModal?.isLoading}
					isSuccess={saveModal?.isSuccess}
					isError={saveModal?.isError}
					handleClose={() => setSaveModal(false)}
					handleSave={() => handleAssignReviewer(saveModal?.status)}
				/>
			)}
		</>
	);
};

export default AssignReviewButton;

import { useState } from 'react';
import Tooltip from '@mui/material/Tooltip';
import PersonIcon from '@mui/icons-material/Person';

import StyledWrapperTable from '../../../pages/IndividualProgram/style';
import TableCustomCheckbox from '../../../components/core/Checkbox/TableCustomCheckbox';

const ReviewToApplicationColumns = () => {
	const [openTooltip, setOpenTooltip] = useState(0);

	return [
		TableCustomCheckbox,
		{
			field: 'reviewer',
			headerName: 'Name',
			renderCell: (cellValues) => {
				const row = cellValues?.row;
				const reviewerName = row?.first_name && `${row?.first_name} ${row?.last_name || ''}`;

				return (
					<Tooltip
						open={openTooltip === `reviewerName-${cellValues?.id}`}
						onOpen={() => setOpenTooltip(`reviewerName-${cellValues?.id}`)}
						onClose={() => setOpenTooltip(0)}
						arrow
						placement="top"
						width={'200px'}
					>
						<StyledWrapperTable.CellContainer margin={!reviewerName && 'auto'}>
							{reviewerName && <PersonIcon color="action" />}

							<StyledWrapperTable.CardRowInfo
								width={'100%'}
								data-testid={`${cellValues?.field}-${cellValues?.id}`}
							>
								{reviewerName || '--'}
							</StyledWrapperTable.CardRowInfo>
						</StyledWrapperTable.CellContainer>
					</Tooltip>
				);
			},
			flex: 1
		},
		{
			field: 'total_reviews',
			headerName: 'Total Assigned',
			flex: 1,
			renderCell: (cellValues) => <>{cellValues?.formattedValue?.toString() || '--'}</>
		},
		{
			field: 'not_started',
			headerName: 'Not Started',
			flex: 1,
			renderCell: (cellValues) => <>{cellValues?.formattedValue?.toString() || '--'}</>
		},
		{
			field: 'in_progress',
			headerName: 'In Progress',
			flex: 1,
			renderCell: (cellValues) => <>{cellValues?.formattedValue?.toString() || '--'}</>
		},
		{
			field: 'submitted',
			headerName: 'Submitted (since inception)',
			flex: 1,
			renderCell: (cellValues) => <>{cellValues?.formattedValue?.toString() || '--'}</>
		}
	];
};

export default ReviewToApplicationColumns;

import { useState, useEffect, useRef } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { Modal, Box, Stack, Alert, Button, Tooltip, CircularProgress } from '@mui/material';
import { LoadingButton } from '@mui/lab';

import StyledWrapper from './style';

import { Table } from '../Table';
import { ItemPageTitle } from '../ItemPageTitle';
import AssignReviewColumns from './AssignReviewColumns';
import ReassignIcon from '../../assets/customIcons/reassignIcon';
import LinkReviewIcon from '../../assets/customIcons/linkReviewIcon';
import ReviewFormIcon from '../../assets/customIcons/reviewForm';
import TrashcanIcon from '../../assets/customIcons/trashcanIcon';
import ReviewStageIcon from '../../assets/customIcons/reviewStageIcon';
import usePaginatedCall from '../../hooks/usePaginatedCall';
import { getUniqueValues } from '../../utils/utilFunctions';
import { getLocalAuth } from '../../utils/environmentUtils';
import {
	useBulkReassignDeleteMutation,
	useBulkReassignKeepMutation,
	useBulkUnassignDeleteMutation,
	useGetAvailableReviewersQuery
} from '../../services/endpoints/reviewEndpoints';

const style = {
	position: 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: '70%',
	maxWidth: '1060px',
	bgcolor: 'background.paper',
	borderRadius: '4px',
	boxShadow:
		'0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12)',
	p: 4
};

export default function BulkReassignReview({
	openModal,
	closeModal,
	type,
	item,
	handleAlert,
	stages,
	resetSelection
}) {
	const accountSource = useSelector((state) => state?.login?.accountSource);
	const apiToken = getLocalAuth(accountSource)?.access_token;
	const reviewerPoolPagination = usePaginatedCall(useGetAvailableReviewersQuery, {
		filterBaseName: 'BULK_ASSIGN_REVIEW',
		pageSize: 10
	});

	const [searchParams] = useSearchParams();
	const [open, setOpen] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [subModal] = useState(searchParams.get('reassignModal'));
	const [chosenReviewer, setChosenReviewer] = useState();
	const [reviewerName, setReviewerName] = useState();

	const outerReviewerRef = useRef();
	outerReviewerRef.current = chosenReviewer;

	const hasReviewers =
		reviewerPoolPagination.isUninitialized ||
		reviewerPoolPagination.loading ||
		reviewerPoolPagination.rowCount > 0;

	const initialColumns = AssignReviewColumns(
		true,
		outerReviewerRef.current,
		setChosenReviewer,
		'Reassign Reviewer Pool',
		null,
		null,
		null,
		setReviewerName
	);

	const [unassignDelete] = useBulkUnassignDeleteMutation();
	const [reassignDelete] = useBulkReassignDeleteMutation();
	const [reassignKeep] = useBulkReassignKeepMutation();

	const getBulkPageTitle = () => {
		const stages = getUniqueValues(Object.values(item));

		return (
			<div style={{ display: 'flex', alignItems: 'center' }}>
				<ReviewStageIcon />
				<div style={{ padding: '16px' }}>
					{'Bulk '}
					{type?.includes('reassign')
						? 'reassign to one of the reviewers in the list for '
						: 'unassign '}
					{stages?.map((e, index) => (
						<>
							{`[${e}]`}
							{index !== stages?.length - 1 && ' and '}
						</>
					))}
					{' & '}
					{type?.includes('keep') ? 'keep' : 'delete'}
					{' the content of the review(s).'}
				</div>
			</div>
		);
	};

	const getSubmitButtonText = () => (
		<>
			{type?.includes('reassign') ? 'REASSIGN' : 'UNASSIGN'}
			{' and '}
			{type?.includes('keep') ? 'KEEP CONTENT' : 'DELETE CONTENT'}
			<div style={{ marginLeft: '5px', display: 'flex', alignItems: 'center' }}>
				{type?.includes('reassign') ? (
					<ReassignIcon isActive={!!chosenReviewer} color={'white'} />
				) : (
					<LinkReviewIcon isActive={true} color={'white'} />
				)}
				{type?.includes('keep') ? (
					<ReviewFormIcon isActive={!!chosenReviewer} customColor={'white'} />
				) : (
					<TrashcanIcon
						isActive={!!chosenReviewer || !type?.includes('reassign')}
						color={'white'}
					/>
				)}
			</div>
		</>
	);

	const handleClose = () => {
		closeModal && closeModal();
		setIsLoading(false);
		setOpen(false);
	};

	useEffect(() => {
		openModal && setOpen(true);
	}, [openModal]);

	const handleSubmit = () => {
		setIsLoading(true);

		for (let key in item) {
			if (item.hasOwnProperty(key)) {
				const updatedValues = item[key].map((item) => item.replace('esi-', ''));
				item[key] = updatedValues;
			}
		}
		const options = {
			payload: item,
			new_reviewer: chosenReviewer,
			apiToken
		};

		if (type === 'unassign & delete') {
			unassignDelete(options)
				.unwrap()
				.then(() => {
					handleAlert('Bulk Unassign & Delete Succesfull!', 'success');
					resetSelection();
					handleClose();
				})
				.catch(() => {
					handleAlert(
						"The unassignment and delete didn't finalize due to a system error. Please try again or contact support.",
						'error'
					);
					handleClose();
				});
		} else if (type === 'reassign & keep') {
			reassignKeep(options)
				.unwrap()
				.then(() => {
					handleAlert(
						`${stages.join(
							', '
						)} successfully reassigned (content of the reviews kept) to ${reviewerName}`,
						'success',
						false
					);
					resetSelection();
					handleClose();
				})
				.catch(() => {
					handleAlert(
						"The reassignment didn't finalize due to a system error. Please try again or contact support.",
						'error'
					);
					handleClose();
				});
		} else if (type === 'reassign & delete') {
			reassignDelete(options)
				.unwrap()
				.then(() => {
					handleAlert(
						`${stages.join(
							', '
						)} successfully reassigned (content of the reviews deleted) to ${reviewerName}`,
						'success',
						false
					);
					resetSelection();
					handleClose();
				})
				.catch(() => {
					handleAlert(
						"The reassignment and deletion didn't finalize due to a system error. Please try again or contact support.",
						'error'
					);
					handleClose();
				});
		}
	};

	const getTooltipText = () => {
		if (!reviewerPoolPagination.loading && !hasReviewers) {
			return 'There are no reviewers in the reviewers pool.';
		} else if (!chosenReviewer) {
			return 'No reviewer selected.';
		} else {
			return '';
		}
	};

	return (
		<div>
			{!subModal && (
				<Modal
					open={open}
					onClose={handleClose}
					aria-labelledby="modal-modal-title"
					aria-describedby="modal-modal-description"
				>
					<Box sx={style}>
						<StyledWrapper.Title>{getBulkPageTitle()}</StyledWrapper.Title>

						{type?.includes('delete') && (
							<Stack sx={{ width: '100%', marginBottom: '20px' }} spacing={2}>
								<Alert variant="outlined" severity="warning">
									The review(s) content will be lost. This action cannot be undone.
								</Alert>
							</Stack>
						)}

						{!type?.includes('unassign') && (
							<>
								<ItemPageTitle text={'Reviewers Pool'} />

								{reviewerPoolPagination.loading && (
									<Box
										sx={{
											display: 'flex',
											width: '100%',
											height: '50vh',
											alignItems: 'center',
											justifyContent: 'center'
										}}
									>
										<CircularProgress />
									</Box>
								)}

								{!reviewerPoolPagination.loading && !hasReviewers && (
									<Stack
										sx={{ height: '50vh', width: '100%', marginBottom: '20px', padding: '20px' }}
										spacing={2}
									>
										<Alert variant="outlined" severity="info">
											There are no reviewers in the reviewers pool.
											<br />
											First, you have to go to the Grants Network portal and add a few.
										</Alert>
									</Stack>
								)}

								{!reviewerPoolPagination.loading && hasReviewers && (
									<Table
										columns={initialColumns}
										rowHeight={true}
										customHeight={'50vh'}
										hideFilters
										disableSelectionOnClick
										getRowId={(cell) => cell?.reviewer_id}
										rows={reviewerPoolPagination?.rows?.results || []}
										rowCount={reviewerPoolPagination?.rowCount}
										pagination={reviewerPoolPagination?.pagination}
										pageSize={reviewerPoolPagination?.pageSize}
										paginationMode={reviewerPoolPagination?.paginationMode}
										onPageChange={(e) => reviewerPoolPagination?.onPageChange(e)}
										onPageSizeChange={(e) => reviewerPoolPagination?.onPageSizeChange(e)}
										page={reviewerPoolPagination?.page}
										loading={reviewerPoolPagination?.loading}
										sortingMode={reviewerPoolPagination?.sortingMode}
										onSortModelChange={(e) => reviewerPoolPagination?.onSortModelChange(e)}
										filterModel={reviewerPoolPagination?.filterModel}
										sortModel={reviewerPoolPagination?.sortModel}
										rowsPerPageOptions={[5, 10, 20, 50]}
									/>
								)}
							</>
						)}

						<StyledWrapper.ButtonContainer>
							<Button onClick={handleClose} variant="outlined" size="small">
								CANCEL
							</Button>
							<Tooltip title={getTooltipText()} arrow placement="top">
								<span>
									<LoadingButton
										loading={isLoading}
										disabled={!chosenReviewer && !type?.includes('unassign')}
										onClick={handleSubmit}
										variant="contained"
										size="large"
									>
										{getSubmitButtonText()}
									</LoadingButton>
								</span>
							</Tooltip>
						</StyledWrapper.ButtonContainer>
					</Box>
				</Modal>
			)}
		</div>
	);
}

import { useEffect, useState } from 'react';
import { useSearchParams, useParams } from 'react-router-dom';
import { useGetBulkAssignReviewersQuery } from '../services/endpoints/reviewEndpoints';
import { getEntityPrefix } from '../utils/utilFunctions';
import usePaginatedCall from './usePaginatedCall';

export const useGetBulkReviewerTable = () => {
	const { programID } = useParams();

	const [searchParams] = useSearchParams();
	const [isLoading, setIsLoading] = useState(true);
	const [rowData, setRowData] = useState([]);

	const pagination = usePaginatedCall(useGetBulkAssignReviewersQuery, {
		filterBaseName: 'BULK_REVIEWS',
		pageSize: 10
	});
	const allReviewers = pagination?.rows;

	useEffect(() => {
		const ApplicationPrefix = getEntityPrefix({
			type: 'application-process',
			isInstance: true
		});

		const StagePrefix = getEntityPrefix({
			type: 'evaluation-stage',
			isInstance: true
		});

		const MasterFormPrefix = getEntityPrefix({
			type: 'review-form',
			isMaters: true
		});

		const FormPrefix = getEntityPrefix({
			type: 'review-form',
			isInstance: true
		});

		const copiedRowData = [];

		allReviewers?.results.forEach((reviewer) => {
			copiedRowData.push({
				hierarchy: [reviewer.reviewer_id],
				reviewer: `${reviewer.first_name} ${reviewer.last_name}`,
				id: copiedRowData.length + 1
			});

			//Handle EvalStages Hierarchy Rows
			let uniqueStageIDs = [];
			reviewer.forms?.forEach((form) => {
				const stage = form.stage;
				const app = stage.application;
				const uniqueStageId = stage.unique_identifier;

				if (!uniqueStageIDs.includes(uniqueStageId)) {
					copiedRowData.push({
						hierarchy: [reviewer.reviewer_id, uniqueStageId],
						submitter: app.submitter,
						appStatus: app.status,
						projectTitle: {
							title: app.project_title || '--',
							displayId: `${ApplicationPrefix}${app.unique_identifier}`
						},
						stageId: `${StagePrefix}${uniqueStageId}`,
						stageName: {
							title: stage.stage_name,
							displayId: `${StagePrefix}${uniqueStageId}`
						},
						status: stage.status,
						reviewForms: [],
						programId: programID,
						masterFormId: `${MasterFormPrefix}${form.master_form.unique_identifier}`,
						id: copiedRowData.length + 1,
						isCurrentStage: app?.form_stage_id === stage.stage_id
					});

					uniqueStageIDs.push(uniqueStageId);
				}
			});
		});

		// Create map object to track how many reviewers exist for each evaluation stage
		const stageReviewerCountMap = {};

		// Populate map object with stageId as keys and array of reviewerIds for each stage as values
		copiedRowData.forEach((rowData) => {
			const reviewerId = rowData.hierarchy[0];
			const stageId = rowData.hierarchy[1];

			if (stageId) {
				if (!stageReviewerCountMap.hasOwnProperty(stageId)) {
					stageReviewerCountMap[stageId] = [reviewerId];
				} else if (!stageReviewerCountMap[stageId].includes(reviewerId)) {
					stageReviewerCountMap[stageId].push(reviewerId);
				}
			}
		});

		copiedRowData.forEach((rowData) => {
			const reviewerId = rowData.hierarchy[0];
			const stageId = rowData.hierarchy[1];

			// Set total reviewers count on original object
			rowData.totalReviewers = stageId ? stageReviewerCountMap[stageId].length : null;

			allReviewers?.results.forEach((reviewer) => {
				if (reviewer.reviewer_id === reviewerId) {
					reviewer.forms?.forEach((form) => {
						if (rowData.stageId?.includes(form.stage.unique_identifier)) {
							rowData.reviewForms?.push({
								title: form.master_form.label,
								displayId: `${FormPrefix}${form.unique_identifier}`
							});
						}
					});
				}
			});
		});

		setRowData(copiedRowData);
		setIsLoading(false);
	}, [allReviewers, searchParams]);

	useEffect(() => {
		pagination?.refreshCall();
	}, [searchParams]);

	return {
		data: rowData,
		isLoading,
		refetch: pagination?.refreshCall,
		pagination
	};
};
